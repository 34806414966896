<template>
  <el-dialog
    title="Approve Vendor"
    :visible="shouldShow"
    @close="$emit('close')"
    width="40%"
  >
    <div>
      Approve this vendor to start operating in the marketplace. Please assign
      an account manager to complete this action
    </div>
    <p class="mt-4">Select Account Manager</p>
    <el-select
      v-model="accountManagerId"
      filterable
      :placeholder="placeholder"
      :value-key="'id'"
    >
      <el-option
        v-for="accountManager in accountManagers"
        :key="accountManager.id"
        :label="formatName(accountManager.name)"
        :value="accountManager.id"
      />
    </el-select>

    <div class="is-flex ml-auto mt-4 is-justify-end">
      <el-button type="plain" @click="$emit('close')">Cancel</el-button>
      <el-button
        type="primary"
        @click="acceptVendor"
        :disabled="approving || !accountManagerId"
        :loading="approving"
        >Approve</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "EdenGardenerSelect",
  props: {
    id: {
      default: "",
      type: String,
    },
    shouldShow: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select team member(s)",
    },
    gardener: {
      type: [String, Number, Object],
      default: "Select gardener",
    },
    itemKey: {
      type: String,
      default: "id",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      approving: false,
      list: [],
      accountManagerId: null,
    };
  },
  computed: {
    // setGardener: {
    //   get() {
    //     return this.gardener;
    //   },
    //   set(value) {
    //     this.$emit(
    //       "update:gardener",
    //       this.returnObject ? value : value[this.itemKey],
    //     );
    //   },
    // },
    accountManagers() {
      return this.$store.getters.account_managers;
    },
  },
  created() {
    if (!this.accountManagers.length) {
      this.$store.dispatch(actions.GET_ACCOUNT_MANAGERS_LIST);
    }
  },
  methods: {
    acceptVendor() {
      // this.$message.error('asjfdksl');
      this.approving = true;
      this.$store
        .dispatch(actions.VENDOR_APPROVAL, {
          id: this.$props.id,
          approval_status: "Approved",
          account_manager_id: this.accountManagerId,
        })
        .then((response) => {
          this.$message.success(response.data.message);

          console.log("happy");
          this.approving = false;
          this.$emit("success", this.$props.id);
          this.$emit("close");
        })
        .catch(() => {
          this.approving = false;
          console.log("sad");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
