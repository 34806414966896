<template>
  <el-dialog
    :title="'Preview imported data'"
    :visible="show"
    @close="closeDialog"
    append-to-body
    :width="'70% !important'"
  >
    <eden-alert :icon="false" type="warning" center v-if="editMode">
      <p class="text-center w-100">
        You are now editing this list.
        <span class="text-primary cursor-pointer" @click="editMode = false">
          Click here to save changes and exit edit mode.
        </span>
      </p>
    </eden-alert>
    <eden-alert v-if="errorMessage" :icon="false" type="warning" center>
      <p class="text-center w-100">
        {{ errorMessage }}
      </p>
    </eden-alert>
    <div class="is-flex justify-between align-center mb-2">
      <p v-if="!editMode">{{ parsedData.length }} product imported</p>
      <p v-else>Select the products you want to remove.</p>

      <el-button type="danger" v-if="editMode" @click="removeProducts">
        Remove selected products
      </el-button>
    </div>

    <el-table
      table-layout="auto"
      :data="paginatedData"
      ref="multipleTableRef"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" v-if="editMode" />
      <el-table-column prop="name" label="Name">
        <template slot-scope="scope">
          <div class="is-flex is-align-center">
            <div class="eden-meal--image">
              <img :src="scope.row.image_url" alt="Image" />
            </div>
            <p>{{ scope.row.name }}</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="description" label="Description" width="200" />
      <el-table-column prop="price" label="Price">
        <template slot-scope="scope">
          {{ formatPrice(scope.row.price) }}
        </template>
      </el-table-column>
      <el-table-column prop="category" label="Category" />
      <el-table-column prop="quantity" label="Quantity" />
    </el-table>

    <eden-pagination
      :total="parsedData.length"
      :current-page.sync="page"
      :page-size="perPage"
    />

    <div class="is-flex is-justify-between mt-4" v-if="!editMode">
      <el-button @click="emits('go-back')"> Go back</el-button>
      <div class="is-flex align-center">
        <el-button @click="editMode = true" class="mr-1"> Edit List </el-button>
        <el-button
          type="primary"
          :disabled="disableConfirmButton"
          @click="submit"
          >Confirm and upload</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Papa from "papaparse";
import * as XLSX from "xlsx";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    bulkUploadFile: File,
  },
  data() {
    return {
      parsedData: [],
      editMode: false,
      multipleTableRef: null,
      productsToDelete: [],
      page: 1,
      perPage: 7,
      errorMessage: null,
    };
  },
  computed: {
    paginatedData() {
      if (this.page === 1) {
        return this.parsedData.slice(0, this.perPage);
      } else {
        return this.parsedData.slice(
          this.perPage * (this.page - 1),
          this.perPage * this.page,
        );
      }
    },
    vendorId() {
      return this.$route.params.id;
    },
    disableConfirmButton() {
      return this.parsedData.some((item) => {
        return (
          item.image_url === undefined ||
          item.name === undefined ||
          item.description === undefined ||
          item.price === undefined ||
          item.category === undefined ||
          item.quantity === undefined
        );
      });
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        const fileType = this.bulkUploadFile?.type.split(".");
        if (fileType[fileType.length - 1] === "sheet") {
          this.parseExcel();
        } else {
          this.parseCSV();
        }
      }
    },
    parsedData: {
      deep: true,
      handler(newData) {
        const hasGoogleDriveLink = newData.some((item) => {
          return item.image_url && item.image_url.includes("drive.google.com");
        });

        if (hasGoogleDriveLink) {
          this.$message.error("Google Drive links are not supported.");
        }
        this.errorMessage = this.getError(newData);
      },
    },
  },
  methods: {
    getError(newData) {
      const index = newData.findIndex(
        (item) =>
          item.image_url === undefined ||
          item.name === undefined ||
          item.description === undefined ||
          item.price === undefined ||
          item.category === undefined ||
          item.quantity === undefined,
      );
      if (index !== -1) {
        const number = index + 1;
        return `Please check number ${number}. There are missing or incomplete items`;
      }
      return null;
    },
    handleSelectionChange(products) {
      products.forEach((product) => {
        this.productsToDelete.push(product.id);
      });
    },
    removeProducts() {
      this.parsedData = this.parsedData.filter(
        (elm) => !this.productsToDelete.includes(elm.id),
      );
      this.$message.success("Product(s) removed successfully");
    },
    async parseExcel() {
      const data = await this.bulkUploadFile?.arrayBuffer();
      const readFile = XLSX.read(data);
      const ws = readFile.Sheets[readFile.SheetNames[0]];
      const extractData = XLSX.utils.sheet_to_json(ws);
      this.parsedData = this.formatEtractedData(extractData);
    },
    parseCSV() {
      Papa.parse(this.bulkUploadFile, {
        header: true,
        skipEmptyLines: false,
        complete: (results) => {
          this.parsedData = this.formatEtractedData(results.data);
        },
      });
    },
    formatEtractedData(results) {
      return results.map((product) => {
        return {
          id: Math.floor(Math.random() * 5000 + 1),
          name: product.Name,
          price: product.Price,
          image_url: product["Image URL"],
          category: product.Category,
          quantity: product.Quantity,
          description: product.Description,
        };
      });
    },
    convertBackToCSV() {
      const file = new File([Papa.unparse(this.parsedData)], "bulk-upload", {
        type: "text/plain",
      });
      return file;
    },
    convertBackToXLSX() {
      const ws = XLSX.utils.json_to_sheet(this.parsedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      const arrayBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      const file = new File([arrayBuffer], "bulk-upload.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      return file;
    },
    async submit() {
      this.$emit("confirm", this.parsedData);
    },
    closeDialog() {
      this.$emit("close");
    },
    emits(eventName) {
      this.$emit(eventName);
    },
  },
};
</script>

<style scoped>
.eden-meal--image {
  height: 40px;
  width: 40px;
  border-radius: 8px !important;
  background: #fff;
  margin-right: 20px;
  overflow: hidden !important;
}

::v-deep(.el-table__row .cell) {
  padding: 0 !important;
}
</style>
