<template>
  <div class="individual-vendor-details">
    <div v-if="loading">loading</div>

    <template v-else>
      <eden-page-header :title="'Vendors'" :subtitle="customerName">
      </eden-page-header>

      <!-- <pre>{{ vendorDetails }}</pre> -->

      <div class="body is-flex">
        <div class="summary">
          <VendorDetailsSummary :data="vendorSummary" />

          <vendorBusinessDetails
            v-if="!notapproved"
            :data="vendorDetails.business_information"
          />
        </div>
        <div class="detail">
          <template v-if="notapproved">
            <eden-information-card :title="'Business Information'">
              <template slot="content">
                <el-table
                  :class="'general'"
                  :data="busInfo"
                  :show-header="false"
                >
                  <el-table-column>
                    <template slot-scope="scope">
                      <template v-if="scope.$index == 0">
                        <el-table
                          :class="'inner-table'"
                          :data="scope.row.data"
                          :show-header="false"
                        >
                          <el-table-column>
                            <template slot-scope="scope">
                              <span class="label font-xsm">{{
                                scope.row.label
                              }}</span>
                              <p class="text font-sm">
                                {{ scope.row.text }}
                              </p>
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                      <template v-else>
                        <span class="label font-xsm">{{
                          scope.row.label
                        }}</span>
                        <p class="text font-sm">
                          {{ scope.row.text }}
                        </p>
                      </template>
                    </template>
                  </el-table-column></el-table
                >
              </template>
            </eden-information-card>
            <eden-information-card
              :title="'Professional Certificate'"
              v-if="documentName"
            >
              <template slot="content">
                <div class="is-flex is-justify-between is-align-center p-3">
                  <div class="label font-sm">{{ documentName }}</div>
                  <el-button
                    plain
                    @click="openDocumentLink"
                    class="text-primary"
                    >View Document</el-button
                  >
                </div>
              </template>
            </eden-information-card>
          </template>
          <template v-else>
            <el-tabs v-model="tab" @tab-click="updateRouteQuery">
              <el-tab-pane label="Other Information" name="other">
                <vendorDetailsOthers
                  :data="vendorDetails"
                  @bank-details-created="getVendor"
                />
              </el-tab-pane>

              <el-tab-pane label="Working hours" name="working_hours">
                <vendorDetailsWorkingHours :data="vendorWorkingHoursDetails" />
              </el-tab-pane>

              <el-tab-pane label="Orders" name="orders">
                <OrderTypes :data="vendorSummary" />
              </el-tab-pane>

              <el-tab-pane label="Products" name="products">
                <VendorDetailsProductsView
                  :vendor-addons="vendorAddons"
                  :tags="tags"
                  @success="getAddons"
                />
              </el-tab-pane>

              <el-tab-pane label="Add-Ons" name="add_ons">
                <VendorAddOns
                  :vendor-addons="vendorAddons"
                  :loading="loadingAddOn"
                  @query="handleSearchAddons"
                  @success="getAddons"
                />
              </el-tab-pane>

              <el-tab-pane label="Reviews" name="reviews">
                <vendorDetailsReviews />
              </el-tab-pane>
            </el-tabs>
          </template>

          <div
            v-if="vendorDetails.business_information?.status === 'Pending'"
            class="is-flex is-justify-end"
          >
            <el-button type="danger" plain @click="acceptOrReject = 'reject'"
              >Reject vendor</el-button
            >
            <el-button type="primary" @click="acceptOrReject = 'approve'"
              >Approve vendor</el-button
            >
          </div>
        </div>
      </div>
    </template>

    <approve-vendor
      @close="acceptOrReject = null"
      v-if="acceptOrReject === 'approve'"
      :should-show="acceptOrReject === 'approve'"
      :id="$route.params.id"
    />

    <reject-vendor
      @close="acceptOrReject = null"
      v-if="acceptOrReject === 'reject'"
      :should-show="acceptOrReject === 'reject'"
      :id="$route.params.id"
    />
  </div>
</template>

<script>
import VendorDetailsSummary from "@/components/Vendors/details/vendor-details-summary.vue";
import vendorBusinessDetails from "./vendor-business-details.vue";
import details from "@/requests/vendors/details.js";
import OrderTypes from "../../components/Vendors/details/OrderTypes.vue";
import vendorDetailsWorkingHours from "../../components/Vendors/details/vendor-details-working-hours.vue";
import vendorDetailsOthers from "../../components/Vendors/details/vendor-details-others.vue";
import vendorDetailsReviews from "../../components/Vendors/details/vendor-details-reviews.vue";
import approveVendor from "../../components/Vendors/pending/approve-vendor.vue";
import rejectVendor from "../../components/Vendors/pending/reject-vendor.vue";
import VendorDetailsProductsView from "../../components/Vendors/details/VendorDetailsProductsView.vue";
import VendorAddOns from "../../components/Vendors/details/VendorAddOns.vue";

export default {
  components: {
    VendorDetailsSummary,
    vendorBusinessDetails,
    vendorDetailsOthers,
    vendorDetailsWorkingHours,
    OrderTypes,
    vendorDetailsReviews,
    approveVendor,
    rejectVendor,
    VendorDetailsProductsView,
    VendorAddOns,
  },
  data() {
    return {
      loading: false,
      vendorDetails: {},
      vendorWorkingHoursDetails: {},
      tab: "other",
      acceptOrReject: null,
      tags: [],
      vendorAddons: {},
      searchQuery: "",
      loadingAddOn: false,
    };
  },
  created() {
    this.getVendor();
    this.getWorkingHours();
    this.getAddons();
  },
  computed: {
    customerName() {
      return this.vendorDetails?.business_information?.business_name;
    },
    notapproved() {
      return (
        this.vendorDetails?.business_information?.status === "Pending" ||
        this.vendorDetails?.business_information?.status === "Rejected"
      );
    },
    vendorSummary() {
      if (!this.vendorDetails?.id) return {};
      return {
        image_url: this.vendorDetails?.business_information.image_url,
        id: this.vendorDetails.id,
        business_name: this.vendorDetails?.business_information.business_name,
        business_type: this.vendorDetails?.business_information.business_type,
        business_status: this.vendorDetails?.business_information?.status,
        is_store_open: this.vendorDetails?.business_information.is_store_open,
        delivery_window:
          this.vendorDetails?.business_information.operation_mode,
        store_url: this.vendorDetails?.store_url,
        vendor_account_creator: {
          ...this.vendorDetails.vendor_account_creator,
        },
      };
    },
    busInfo() {
      return [
        {
          data: [
            {
              label: "Business Phone Number",
              text:
                this.vendorDetails.business_information.business_phone_number ??
                "-",
            },
            {
              label: "CAC Registration Number",
              text:
                this.vendorDetails.business_information
                  .cac_registration_number ?? "-",
            },
          ],
        },
        {
          label: "Business Address",
          text: this.vendorDetails.business_information.business_address ?? "-",
        },
      ];
    },
    documentName() {
      if (!this.vendorDetails.business_information.certificate) return false;

      const documentLink =
        this.vendorDetails.business_information.certificate.document_link;
      const vendorAccountCreator = this.vendorDetails.vendor_account_creator;

      const format = documentLink.split(".").pop().toLowerCase();
      const documentType = "Business Certificate";
      const documentFormat = format === "png" ? "png" : "pdf";

      return `${vendorAccountCreator.name} ${documentType}.${documentFormat}`;
    },
    vendorId() {
      return this.$route.params.id;
    },
  },

  methods: {
    async getVendor() {
      this.loading = true;
      await details
        .list(this.vendorId)
        .then((response) => {
          if (response.data.status) {
            this.vendorDetails = response.data.data;
            this.getTags();
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err.response.data.message);
        });
    },
    getWorkingHours() {
      this.loading = true;
      details
        .workingHours(this.vendorId)
        .then((response) => {
          if (response.data.status) {
            this.loading = false;
            this.vendorWorkingHoursDetails = response.data.data;
            // this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err.response.data.message);
        });
    },
    updateRouteQuery(tab) {
      const { t } = this.$route.query;

      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "vendors.details",
        params: { id: this.vendorId },
        query: { t: tab.name },
      });
    },
    openDocumentLink() {
      window.open(
        this.vendorDetails.business_information.certificate
          ? this.vendorDetails.business_information.certificate.document_link
          : "",
        "_blank",
      );
    },
    async getTags() {
      const business_id =
        this.vendorDetails.business_information.business_type_id;
      await details
        .tags(business_id)
        .then((response) => {
          if (response.data.status) {
            this.tags = response.data.data;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        });
    },
    async getAddons() {
      this.loadingAddOn = true;
      const params = {
        vendor_uuid: this.vendorId,
        query: this.searchQuery || "",
      };
      await details
        .addons(params)
        .then((response) => {
          if (response.data.status) {
            this.vendorAddons = response.data.data;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.loadingAddOn = false;
        });
    },
    handleSearchAddons(query) {
      this.searchQuery = query;
      this.getAddons();
    },
  },
};
</script>

<style lang="scss" scoped>
$-summary-width: 320px;

.p-3 {
  padding: 12px 10px;
}
.individual-vendor-details {
  .body {
    margin-top: 45px;

    .detail {
      margin-left: 30px;
      width: calc(100% - #{$-summary-width});
    }
  }

  @media (max-width: 992px) {
    .body {
      flex-wrap: wrap;

      .detail {
        margin: 0;
        width: 100%;
      }
    }
  }
}
</style>
