<template>
  <eden-information-card :title="'Business Information'">
    <template slot="content">
      <customer-information :information="information" />
    </template>
  </eden-information-card>
</template>

<script>
import CustomerInformation from "@/components/Customers/Individual/Profile/CustomerInformation";
// import { formatSignupMeta } from "@/components/Customers/Individual/format-data";
export default {
  name: "CustomerProfileInformation",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    CustomerInformation,
  },
  data() {
    return {};
  },
  computed: {
    deleted() {
      return this.data.deleted_at !== null ? true : false;
    },
    information() {
      return [
        {
          label: "Business Bio",
          text: this.data.business_bio,
        },
        {
          label: "Business Phone Number",
          text: this.data.business_phone_number,
        },
        {
          label: "CAC Registration Number",
          text: this.data.cac_registration_number,
        },
        {
          label: "Country",
          text: this.data.country,
        },
        {
          label: "Business Address ",
          text: this.data.business_address,
        },
        {
          label: "City",
          text: this.data?.location_area,
        },
        // {
        //   label: "Operation Mode",
        //   text: this.data.operation_mode,
        // },
      ];
      //   const information = this.deleted
      //     ? [
      //         {
      //           label: "Joined on",
      //           text:
      //             this.formatDateAndTime(this.data.joined, "dddd do, m, y") ||
      //             "-",
      //         },
      //         {
      //           label: "Deleted on",
      //           text:
      //             this.formatDateAndTime(this.data.deleted_at, "dddd do, m, y") ||
      //             "-",
      //         },
      //       ]
      //     : [
      //         {
      //           label: "Birthday",
      //           text: this.formatDate(this.data.birthday, "do m, y") || "-",
      //         },
      //         {
      //           label: "Joined on",
      //           text:
      //             this.formatDateAndTime(this.data.joined, "dddd do, m, y") ||
      //             "-",
      //         },
      //         {
      //           label: "Last updated",
      //           text:
      //             this.formatDateAndTime(this.data.updated, "dddd do, m, y") ||
      //             "-",
      //         },
      //         {
      //           label: `Player Ids (${
      //             this.data.player_ids ? this.data.player_ids.length : ""
      //           })`,
      //           text: this.data.player_ids ? this.data.player_ids[0] : "",
      //         },
      //         // {
      //         //   label: "Sendbird ID",
      //         //   text: this.data.sendbird_id,
      //         // },
      //         {
      //           label: "Getstream ID",
      //           text: this.data.getstream_id,
      //         },
      //       ];

      //   if (this.data.joined_via) {
      //     information.splice(2, 0, {
      //       label: "Joined via",
      //       text: "Lighthouse",
      //     });
      //   }
      //   if (this.data.created_from === "GreenHouse") {
      //     information.splice(
      //       information.length - 3,
      //       0,
      //       {
      //         label: "Source",
      //         text: formatSignupMeta(this.data.signup_meta).source,
      //       },
      //       {
      //         label: "Medium",
      //         text: this.data.signup_meta ? this.data.signup_meta.medium : "-",
      //       },
      //       {
      //         label: "Content",
      //         text: this.data.signup_meta ? this.data.signup_meta.content : "-",
      //       },
      //     );
      //   }
      //   return information;
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  border-radius: 16px !important;
  margin-bottom: 16px !important;
  border: 1px solid #f0f4f2 !important;
}
</style>
