<template>
  <el-dialog
    :title="'Update Inventory'"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <el-form :model="form" label-position="top">
      <el-row type="flex" :gutter="15" class="is-flex-wrap">
        <el-col :md="form.is_unlimited ? 24 : 12">
          <el-form-item label="Quantity Available" prop="unlimited">
            <el-select v-model="form.is_unlimited" placeholder="quantity">
              <el-option label="Limited" :value="false" />
              <el-option label="Unlimited" :value="true" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="12" v-if="!form.is_unlimited" class="mt-1">
          <el-form-item label=" " prop="quantity" :rules="validateField()">
            <el-input
              v-model="form.quantity"
              type="number"
              placeholder="Insert available quantity here"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :loading="loading"
        :disabled="isDisabled"
        @click="saveInventory"
      >
        Save
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import details from "@/requests/vendors/details.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    unlimited: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: null,
    },
    productId: String,
  },

  data() {
    return {
      form: {
        is_unlimited: this.unlimited,
        quantity: this.quantity,
      },
      loading: false,
    };
  },

  watch: {
    unlimited: {
      handler(newValue) {
        // Update form.is_unlimited when the prop changes
        this.form.is_unlimited = newValue;
      },
      immediate: true,
    },
    quantity: {
      handler(newValue) {
        this.form.quantity = newValue;
      },
      immediate: true,
    },
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isDisabled() {
      return !this.form.quantity && !this.form.is_unlimited;
    },
  },
  created() {},

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        is_unlimited: this.unlimited,
        quantity: this.quantity,
      };
    },
    async saveInventory() {
      this.loading = true;
      const payload = {
        product_ids: [this.productId],
        in_stock: true,
        is_unlimited: this.form.is_unlimited,
        quantity: this.form.is_unlimited ? 0 : this.form.quantity,
      };
      await details
        .updateInventory(this.$route.params.id, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.productId);
            this.closeModal();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
