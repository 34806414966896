<template>
  <section>
    <el-drawer :visible.sync="setShow" direction="rtl" @close="closeEvent">
      <template slot="title">
        <span class="text-grey-primary">{{ formatText(action) }} Product</span>
      </template>

      <div class="el-drawer--content" v-loading="loading">
        <div class="el-drawer--content__body">
          <el-form
            :model="form"
            label-position="top"
            ref="form"
            class="is-flex is-flex-column gap-1"
          >
            <el-row type="flex" class="is-flex-wrap">
              <el-col :md="24" class="is-flex is-flex-column align-center">
                <eden-image-upload
                  :image-url.sync="form.productImg_url"
                  :image-text="'product image'"
                  :image-folder="'products'"
                />
                <p class="text-center mt-1">
                  Use a square image (1:1 aspect ratio) for best display
                </p>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col>
                <el-form-item label="Name" prop="name" :rules="validateField()">
                  <el-input
                    v-model="form.name"
                    type="text"
                    placeholder="Product Name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item
                  label="Description"
                  prop="description"
                  :rules="validateField()"
                >
                  <el-input
                    v-model="form.description"
                    :rows="5"
                    type="textarea"
                    placeholder="Tell your customers more about this product and what to expect. You can include things like serving size or packaging details or anything else."
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="15" class="is-flex-wrap">
              <el-col :md="form.discount ? 12 : 24">
                <el-form-item label="Price" prop="price">
                  <el-input
                    v-model="form.price"
                    type="number"
                    :placeholder="LOCATION === 'NG' ? '₦' : 'KES'"
                  />
                </el-form-item>
              </el-col>
              <el-col :md="12" v-if="form.discount">
                <el-form-item label="Discounted Price" prop="discountPrice">
                  <el-input
                    v-model="form.discountPrice"
                    type="number"
                    :placeholder="LOCATION === 'NG' ? '₦' : 'KES'"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="100">
                <el-checkbox
                  v-model="form.discount"
                  class="is-flex is-align-self-start"
                >
                  <div>
                    <p style="font-weight: 500; font-size: 14px">
                      Display a discounted price
                    </p>
                    <p class="text-wrap">
                      Select this if you would like to offer your product at a
                      reduced price. Customers will see both the original and
                      discounted price.
                    </p>
                  </div>
                </el-checkbox>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="15" class="is-flex-wrap">
              <el-col :md="form.is_unlimited ? 24 : 12">
                <el-form-item label="Quantity Available" prop="unlimited">
                  <el-select v-model="form.is_unlimited" placeholder="quantity">
                    <el-option label="Limited" :value="false" />
                    <el-option label="Unlimited" :value="true" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="12" v-if="!form.is_unlimited" class="mt-1">
                <el-form-item label=" " prop="quantity">
                  <el-input
                    v-model="form.quantity"
                    type="number"
                    placeholder="Insert available quantity here"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <div class="line-divider"></div>

            <el-row>
              <el-col>
                <p class="form-headers">Add-ons (optional)</p>
                <el-form-item
                  label="Increase your sales by suggesting additional products that your
                customers can buy with this product."
                  prop="addon"
                >
                  <el-select
                    class="hidden-tag"
                    v-model="form.addOn"
                    multiple
                    :value-key="'id'"
                    filterable
                    placeholder="Select an add-on"
                  >
                    <el-option
                      v-for="(item, index) in vendorAddons.data"
                      :key="index"
                      :label="item.name"
                      :value="item"
                    >
                      <span
                        style="float: left; max-width: 100px; text-wrap: wrap"
                        >{{ formatName(item.name) }}</span
                      >
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                      >
                        {{ formatPrice(item.price) }}
                      </span>
                    </el-option>
                  </el-select>
                </el-form-item>

                <span class="create-new" @click="showAddonForm = true">
                  <i class="el-icon-circle-plus-outline mr-1"></i>
                  Create a new add-on
                </span>

                <div
                  v-for="(addOn, index) in form.addOn"
                  :key="index"
                  class="is-flex justify-between mt-3"
                >
                  <div class="is-flex gap-1">
                    <img
                      :src="addOn.image_url"
                      :alt="addOn.name"
                      class="addon-img"
                    />
                    <div>
                      <p>{{ addOn.name }}</p>
                      <p>{{ formatPrice(addOn.price) }}</p>
                    </div>
                  </div>
                  <div class="is-flex gap-1 is-align-center">
                    <i
                      class="el-icon-close text-danger"
                      @click="removeAddon(index)"
                    ></i>
                    <p class="text-danger">Remove</p>
                  </div>
                </div>
              </el-col>
            </el-row>

            <div class="line-divider"></div>

            <el-row>
              <el-col>
                <p class="form-headers">Categories</p>
                <el-form-item
                  label="Use categories to group your products and make it easier for your customers to find them e.g. Soups, Protein, Breakfast Meals, Best Sellers etc."
                  prop="category"
                  :rules="validateField()"
                >
                  <el-select
                    class="hidden-tag"
                    filterable
                    multiple
                    :value-key="'id'"
                    v-model="form.category"
                    placeholder="Select a category"
                  >
                    <el-option
                      v-for="(item, index) in categories"
                      :key="index"
                      :label="item.name"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
                <span class="create-new" @click="showCategoryForm = true">
                  <i class="el-icon-circle-plus-outline mr-1"></i>
                  Create a new category
                </span>

                <div
                  v-for="(category, index) in form.category"
                  :key="index"
                  class="is-flex justify-between mt-3"
                >
                  <div>
                    <p>{{ category.name }}</p>
                  </div>
                  <div class="is-flex gap-1 is-align-center">
                    <i
                      class="el-icon-close text-danger"
                      @click="removeCategory(index)"
                    ></i>
                    <p class="text-danger">Remove</p>
                  </div>
                </div>
              </el-col>
            </el-row>

            <div class="line-divider"></div>

            <el-row>
              <el-col>
                <p class="form-headers">Tags (optional)</p>
                <el-form-item
                  label="Use tags to make your product discoverable by customers who are looking for similar products on the Marketplace. You can select more than one tag that accurately reflects your product."
                  prop="quantity"
                >
                  <el-select
                    multiple
                    v-model="form.tags"
                    :value-key="'id'"
                    placeholder="Select tags"
                  >
                    <el-option
                      v-for="(item, index) in tags"
                      :key="index"
                      :label="item.name"
                      :value="item.uuid"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="line-divider"></div>

            <el-row type="flex">
              <el-col class="is-flex is-align-center gap-1">
                <div class="is-flex is-flex-column">
                  <p class="form-headers">Publish Product</p>
                  <p style="font-weight: 400; font-size: 14px">
                    Publishing your product means it will be visible to
                    customers and they can order it. If you don’t publish, it’ll
                    be saved as a draft.
                  </p>
                </div>
                <el-switch
                  v-model="form.isDraft"
                  :active-value="false"
                  :inactive-value="true"
                  :width="90"
                >
                </el-switch>
              </el-col>
            </el-row>

            <div class="line-divider"></div>

            <el-row type="flex">
              <el-col class="is-flex is-align-center gap-1">
                <div class="is-flex is-flex-column">
                  <p class="form-headers">Make this a pre-order product?</p>
                  <p style="font-weight: 400; font-size: 14px">
                    Making this a pre-order product means customers can only
                    pre-order it (they won't be able to order it for immediate
                    delivery)
                  </p>
                </div>
                <el-switch
                  v-model="form.isPreOrder"
                  :active-value="true"
                  :inactive-value="false"
                  :width="90"
                >
                </el-switch>
              </el-col>
            </el-row>
            <el-row type="flex" v-if="form.isPreOrder">
              <el-col :md="24">
                <el-form-item
                  label="Set a minimum pre-order timeline (days)"
                  prop="min_days_preorder_timeline"
                >
                  <el-input
                    v-model="form.preOrderTimeline"
                    type="number"
                    v-number
                  >
                    <span slot="suffix">
                      <p class="my-1">days</p>
                    </span>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="el-drawer--footer is-align-center w-100">
        <el-button
          v-if="action == 'add'"
          type="primary"
          class="w-100"
          :loading="adding"
          :disabled="isDisabled"
          @click="createVendorProduct"
          >Add Product</el-button
        >
        <el-button
          v-else
          type="primary"
          class="w-100"
          :loading="adding"
          :disabled="isDisabled"
          @click="updateProduct"
          >Save Changes</el-button
        >
      </div>
    </el-drawer>
    <CategoryForm :show.sync="showCategoryForm" @success="getCategories" />
    <AddonForm :show.sync="showAddonForm" @success="$emit('success')" />
  </section>
</template>

<script>
import details from "@/requests/vendors/details.js";
import CategoryForm from "./CategoryForm.vue";
import AddonForm from "./AddonForm.vue";
export default {
  name: "ProductForm",
  components: { CategoryForm, AddonForm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    productData: {
      default: () => ({}),
    },
    tags: {
      default: () => ({}),
    },
    product: {
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    vendorAddons: {
      default: () => ({}),
    },
  },
  data() {
    return {
      adding: false,
      showCategoryForm: false,
      showAddonForm: false,
      form: {
        productImg_url: "",
        name: "",
        description: "",
        price: null,
        is_unlimited: true,
        discountPrice: 0,
        discount: false,
        quantity: 0,
        addOn: [],
        category: [],
        tags: [],
        isDraft: true,
        isPreOrder: false,
        preOrderTimeline: null,
      },
      categories: [],
      LOCATION: localStorage.getItem("eden-crm-location"),
    };
  },
  watch: {
    "show"() {
      if (this.show) {
        if (this.action === "edit") {
          this.setData();
        }
      }
    },
    "product": {
      handler: "setData",
    },
    "form.isPreOrder"(newValue) {
      if (newValue) {
        this.form.preOrderTimeline = 1;
      } else {
        this.form.preOrderTimeline = null;
      }
    },
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isDisabled() {
      return (
        !this.form.productImg_url &&
        !this.form.name &&
        !this.form.description &&
        !this.form.price &&
        this.form.is_unlimited &&
        this.form.category.length === 0
      );
    },
  },
  created() {
    this.getCategories();
    this.form = {
      productImg_url: "",
      name: "",
      description: "",
      price: null,
      is_unlimited: true,
      discountPrice: 0,
      discount: false,
      quantity: 0,
      addOn: [],
      category: [],
      tags: [],
      isDraft: true,
      isPreOrder: false,
      preOrderTimeline: null,
    };
  },
  methods: {
    selectedIds(data) {
      return data.map((item) => item.uuid);
    },
    removeAddon(index) {
      this.form.addOn.splice(index, 1);
    },
    removeCategory(index) {
      this.form.category.splice(index, 1);
    },
    closeEvent() {
      this.setShow = false;
      this.form = {
        productImg_url: "",
        name: "",
        description: "",
        price: null,
        is_unlimited: true,
        discountPrice: 0,
        discount: false,
        quantity: 0,
        addOn: [],
        category: [],
        tags: [],
        isDraft: true,
        isPreOrder: false,
        preOrderTimeline: null,
      };
    },
    async getCategories() {
      await details
        .categories(this.$route.params.id)
        .then((response) => {
          if (response.data.status) {
            this.categories = response.data.data;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        });
    },
    createPayload() {
      return {
        image_url: this.form.productImg_url,
        name: this.form.name,
        description: this.form.description,
        price: Number(this.form.price),
        is_unlimited: this.form.is_unlimited,
        quantity: this.form.quantity,
        use_discounted_price: this.form.discount,
        is_draft: this.form.isDraft,
        is_preorder: this.form.isPreOrder,
        min_days_preorder_timeline: Number(this.form.preOrderTimeline),
        category_ids: this.selectedIds(this.form.category),
        addon_ids: this.selectedIds(this.form.addOn),
        tag_ids: this.form.tags,
        discounted_price: this.form.discountPrice,
      };
    },
    createVendorProduct() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        details
          .createVendorProduct(this.$route.params.id, this.createPayload())
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.closeEvent();
              this.$emit("product-created");
            }
          })
          .catch((err) => {
            this.$message.error(err.response.data.message);
          })
          .finally(() => {
            this.adding = false;
          });
        return true;
      });
    },
    async updateProduct() {
      await details
        .editProduct(
          this.$route.params.id,
          this.product.uuid,
          this.createPayload(),
        )
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.closeEvent();
            this.$emit("product-created");
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.adding = false;
        });
      return true;
    },
    setData() {
      this.form = {
        productImg_url: this.product.image_url,
        name: this.product.name,
        description: this.product.description,
        price: this.product.price,
        is_unlimited: this.product.is_unlimited,
        discountPrice: this.product.discounted_price,
        discount: this.product.use_discounted_price,
        quantity: this.product.quantity,
        addOn: this.product.addons,
        category: this.product.categories ?? [],
        tags: this.selectedIds(this.product.tags ?? []),
        isDraft: this.product.is_draft,
        isPreOrder: this.product.is_preorder,
        preOrderTimeline: this.product.min_days_preorder_timeline,
      };
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1.5rem;
}

.line-divider {
  border-bottom: 1px solid #f0f4f2;
  margin: 32px 0px;
}

.form-headers {
  font-weight: 700;
  font-size: 16px;
  line-height: 2rem;
}

.addon-img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.create-new {
  color: #03a84e;
  font-weight: 500;
  cursor: pointer;
}

:deep .hidden-tag .el-tag {
  display: none !important;
}
</style>
