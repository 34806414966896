<template>
  <div>
    <eden-table-actions :title="'Reviews'" :show-search="false">
      <template slot="actions">
        <vendor-reviews-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <template v-if="vendorReviews.data.length">
        <div
          class="reviews"
          v-for="(feedback, index) in vendorReviews.data"
          :key="index"
        >
          <div class="reviews-author">
            <div class="is-flex is-justify-between is-align-center">
              <h6>
                {{ feedback.customer_name }}
                <span class="ml-2">#{{ feedback.order_id }}</span>
              </h6>

              <div class="text-grey-tertiary">
                {{ formatDateAndTime(feedback.created_at, "12h") }}
              </div>
            </div>
            <div class="rating">
              <el-rate
                :value="Number(feedback.rating)"
                disabled
                text-color="#ff9900"
              />
            </div>
            <el-tag
              type="info"
              class="mt-2 mb-2"
              v-for="(tag, index) in JSON.parse(feedback?.tags)"
              :key="index"
            >
              {{ tag }}
            </el-tag>
            <div class="is-flex justify-between reviews-action">
              <div class="w-80">
                <p>
                  {{ feedback.description }}
                </p>
              </div>
              <div>
                <el-button plain @click="viewOrder(feedback)">
                  View Order
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <eden-pagination
          v-if="vendorReviews.data.length"
          :from="vendorReviews.from"
          :to="vendorReviews.to"
          :total="vendorReviews.total"
          :current-page.sync="page"
          :page-size="vendorReviews.per_page"
        />
      </template>
      <eden-content-empty v-else :text="'No Reviews'" />
    </template>
  </div>
</template>

<script>
import details from "@/requests/vendors/details.js";
import VendorReviewsFilter from "@/components/Vendors/details/VendorReviewsFilter";
export default {
  components: {
    VendorReviewsFilter,
  },
  data() {
    return {
      loading: false,
      page: 1,
      showPagination: false,
      vendorReviews: {},
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
    };
  },
  computed: {},
  watch: {
    page() {
      this.getReviews();
    },
  },
  created() {
    this.getReviews();
  },
  methods: {
    getReviews() {
      this.loading = true;
      details
        .reviews(this.$route.params.id, this.page)
        .then((response) => {
          if (response.data.status) {
            this.loading = false;
            this.vendorReviews = response.data.data;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          this.loading = false;
        });
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      const stringedParams = this.sortObjectAsParams(params);
      details
        .reviewsFilter(this.$route.params.id, stringedParams)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData = response.data.data;
          // this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getReviews();
    },
    viewOrder(feedback) {
      this.$router.push({
        name: `ordersAndSchedule.details`,
        params: { id: feedback.order_uuid },
      });
      // router.push({ name: ROUTES.ORDERS.DETAILS, params: { id: feedback.order_uuid } })
    },
  },
};
</script>

<style lang="scss" scoped>
.reviews {
  display: flex;
  margin: 40px 0 20px;

  &-author {
    margin-left: 15px;
    width: 100%;
    border-bottom: 1px solid #f0f4f2;
    padding-bottom: 15px;

    h6 {
      font-size: 0.95rem;
      font-family: "CerebriSans", sans-serif;
      font-weight: 500;
      margin-bottom: 12px;
    }

    span {
      position: relative;
      display: inline-block;
      font-size: 1rem !important;
      font-weight: 400;
      color: var(--eden-grey-tertiary);
    }

    p {
      line-height: 1.8;
      font-size: 0.9rem;
    }
  }
}

.w-80 {
  width: 80%;
}
</style>
