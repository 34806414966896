<template>
  <el-dialog
    :title="'Create a Category'"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <el-form :model="form" label-position="top">
      <el-row>
        <el-col>
          <el-form-item label="Name" prop="name">
            <el-input v-model="form.name" type="text"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :loading="loading"
        :disabled="isDisabled"
        @click="createCategory"
      >
        Create Category
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import details from "@/requests/vendors/details.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        name: "",
      },
      loading: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isDisabled() {
      return !this.form.name.trim();
    },
  },
  created() {},

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        name: "",
      };
    },
    createCategory() {
      this.loading = true;
      details
        .createCategory(this.$route.params.id, this.form)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeModal();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
