<template>
  <el-dialog
    :title="`${formatText(action)} Bank Information`"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <p class="mb-2">
      Vendor’s Payouts from orders will be made into this bank account.
    </p>
    <el-form :model="form" label-position="top">
      <el-row>
        <el-col>
          <el-form-item label="Bank Name" prop="banks">
            <el-select
              :value-key="'id'"
              filterable
              v-model="form.bank"
              placeholder="Select bank"
            >
              <el-option
                v-for="(item, index) in banks"
                :key="index"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Account Number" prop="AccNumber">
            <el-input v-model="form.accountNumber" type="number"></el-input>
          </el-form-item>
          <el-form-item v-if="bankVerified" label="Account Name" prop="AccName">
            <el-input
              v-model="form.accountName"
              type="text"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="bankVerified"
        type="primary"
        :loading="loading"
        @click="saveBankDetails"
      >
        Save Bank Information
      </el-button>
      <el-button
        v-else
        type="primary"
        :loading="loading"
        :disabled="isDisabled"
        @click="verifyBankDetails"
      >
        Verify Account
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import details from "@/requests/vendors/details.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    banks: {
      default: () => ({}),
    },
  },

  data() {
    return {
      form: {
        bank: [],
        accountNumber: null,
        accountName: "",
      },
      loading: false,
      bankVerified: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isDisabled() {
      return !this.form.bank && !this.form.accountNumber;
    },
    selectedbankName() {
      return this.form.bank ? this.form.bank.name : "";
    },

    selectedbankBankCode() {
      return this.form.bank ? this.form.bank.bank_code : "";
    },
  },
  created() {},

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        banks: [],
        accountNumber: null,
        accountName: "",
      };
    },
    verifyBankDetails() {
      this.loading = true;
      const payload = {
        bank_code: this.selectedbankBankCode,
        account_number: this.form.accountNumber,
      };
      details
        .verifyBank(payload)
        .then((response) => {
          if (response.data.status) {
            this.form.accountName = response.data.data.account_name;
            this.bankVerified = true;
            this.$message.success(response.data.message);
          }
        })
        .catch(() => {
          this.$message.error(
            "Incorrect bank account information, Please check that the details entered are correct",
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveBankDetails() {
      this.loading = true;
      const payload = {
        vendor_uuid: this.$route.params.id,
        bank_name: this.selectedbankName,
        account_name: this.form.accountName,
        account_number: this.form.accountNumber,
      };
      details
        .setBank(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.closeModal();
            this.$emit("bank-details-created");
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
p {
  font-weight: 400;
  font-size: 14px;
}
</style>
