<template>
  <eden-confirm-dialog
    title="Delete this add-on?"
    button-type="danger"
    button-text="Delete add-on"
    :show.sync="shouldShow"
    @confirm="deleteProduct"
  >
    <p>
      Deleting this add-on will remove it as an option from the products it has
      been added to. However, this will not affect the products themselves.
    </p>
  </eden-confirm-dialog>
</template>

<script>
import details from "@/requests/vendors/details";

export default {
  name: "ProductDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    addonId: String,
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    async deleteProduct() {
      const payload = {
        addon_ids: [this.addonId],
        vendor_uuid: this.$route.params.id,
      };
      await details
        .deleteAddons({ data: payload })
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
