<template>
  <eden-confirm-dialog
    :title="`${draftText} this product`"
    :button-type="buttonType"
    :button-text="`${draftText} product`"
    :show.sync="shouldShow"
    @confirm="publishProduct"
  >
    <p v-if="isDraft">
      Publishing this product means customers will be able to view and order it.
    </p>
    <p v-else>
      If you unpublish this product, it will be removed from the storefront and
      customers will no longer be able to view or order it unless you publish it
      back.
    </p>
  </eden-confirm-dialog>
</template>

<script>
import details from "@/requests/vendors/details";

export default {
  name: "ProductDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productId: String,
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    buttonType() {
      return this.isDraft ? "success" : "danger";
    },
    draftText() {
      return `${this.isDraft ? "Publish" : "Unpublish"}`;
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    async publishProduct() {
      const requestType = this.isDraft ? "publish" : "unpublish";
      const payload = {
        product_ids: [this.productId],
        request_type: requestType,
      };
      await details
        .publish(this.$route.params.id, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.productId);
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
