<template>
  <eden-information-card>
    <template slot="content">
      <div class="customer-profile">
        <div class="summary-business-img" v-if="data.image_url">
          <img :src="data.image_url" :alt="data.business_name" />
        </div>
        <eden-avatar v-else :src="data.image_url" :size="70" />
        <div class="my-2">{{ data.id }}</div>
        <h6 class="name font-lg">{{ data.business_name }}</h6>
        <div>{{ data.business_type }}</div>

        <div class="px-2 py-1">
          <a :href="data.store_url" target="_blank" rel="noopener noreferrer">
            <span
              class="text-primary font-base text-underline is-flex is-justify-center align-end"
            >
              {{ formattedStoreUrl }}
              <img src="@/assets/img/external-link.svg" />
            </span>
          </a>
        </div>

        <el-button type="plain" size="medium" @click="edit"
          >Edit Profile</el-button
        >

        <p class="email font-base text-grey-tertiary">
          {{ formatDeletedEmail(data.email) }}
        </p>
        <div class="tags">
          <eden-tag :value="data.business_status" />
          <el-tag :type="data.is_store_open ? 'plain' : 'danger'">
            {{ data.is_store_open ? " Open" : "Closed" }}
          </el-tag>
        </div>
      </div>
      <customer-information :information="information" :customer="data" />
    </template>
  </eden-information-card>
</template>

<script>
import CustomerInformation from "@/components/Customers/Individual/Profile/CustomerInformation";

export default {
  name: "CustomerProfileSummary",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    CustomerInformation,
  },
  data() {
    return {};
  },
  computed: {
    information() {
      return [
        {
          label: "Name",
          text: this.data.vendor_account_creator?.name,
        },
        {
          label: "Email",
          text: this.data.vendor_account_creator?.email_address,
        },
        {
          label: "Account Manager",
          text: this.data.vendor_account_creator?.account_manager,
        },
        // {
        //   label: "Customer Type",
        //   text: this.data.corporation
        //     ? `${this.data.corporation} Employee`
        //     : this.formatPersona(this.data.persona),
        // },
        // {
        //   label: "Next Charge Date",
        //   text: this.formatDate(this.data.next_charge_date, "dddd do, m, y"),
        // },
      ];
    },
    deleted() {
      return this.data.customer_status === "deleted" ? true : false;
    },
    formattedStoreUrl() {
      return this.data?.store_url?.replace(/^https?:\/\//, "");
    },
  },
  methods: {
    edit() {
      this.$router.push({ name: "vendors.detail.edit" });
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  border-radius: 16px !important;
  margin-bottom: 16px !important;
}
.customer-profile {
  margin-bottom: 30px;
  text-align: center;
  .summary-business-img {
    height: 146px;
    width: 272px;
    margin: 0 auto;
    border-radius: 8px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    font-family: "CerebriSans", sans-serif;
    font-weight: 500;
    margin-bottom: 7px;
  }

  .email {
    font-family: "CerebriSans", sans-serif;
    font-weight: 400;
  }

  .tags {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
  }

  .rewards {
    display: inline-block;
    margin: 5px 0;
    font-size: 0.875rem;
    color: var(--eden-green-primary);
  }

  .el-button {
    margin: 10px auto;
  }
}
</style>
