<template>
  <eden-filter :width="270" :properties.sync="properties" :disabled="disabled" :loading="loading" @open="getData"
    @reset="reset" @filter="filter" />
</template>
  
<script>
import vendor from "@/requests/vendors/details";

export default {
  name: "VendorReviewsFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        rating_filter: {
          key: "rating_filter",
          label: "Rating",
          type: "list",
          options: [
            {
              label: "1 Stars",
              value: "1",
            },
            {
              label: "2 Stars",
              value: "2",
            },
            {
              label: "3 Stars",
              value: "3",
            },
            {
              label: "4 Stars",
              value: "4",
            },
            {
              label: "5 Stars",
              value: "5",
            },
          ],
          value: [],
        },
        product_filter: {
          key: "product_filter",
          label: "Product",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        date: {
          key: ["date_filter[from]", "date_filter[to]"],
          label: "Date",
          type: "daterange",
          value: [],
        },
      },
    };
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.getProducts()
    },
    getProducts() {
      if (this.properties.product_filter.options.length) {
        return;
      }
      vendor
        .vendorProducts(this.$route.params.id)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data.data, "Whatass")
            this.properties.product_filter.options = response.data.data.data.map(
              (product) => {
                return {
                  label: this.formatText(product.name),
                  value: product.uuid,
                };
              }
            );
          }
        })
        .catch();
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });
      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>
  
<style lang="scss" scoped></style>
  