<template>
  <el-dialog
    title="Reject Vendor"
    :visible="shouldShow"
    @close="$emit('close')"
    width="40%"
  >
    <div>Select the reason for rejecting vendor to complete this action.</div>
    <p class="mt-4">Reason</p>
    <el-select
      v-model="setReason"
      filterable
      :placeholder="placeholder"
      :value-key="'id'"
    >
      <el-option
        v-for="reason in reasons"
        :key="reason"
        :label="formatName(reason)"
        :value="reason"
      />
    </el-select>
    <el-button
      class="ml-auto mt-5"
      :disabled="rejecting || !setReason.length"
      type="danger"
      :loading="rejecting"
      @click="rejectVendor"
    >
      Reject vendor</el-button
    >
  </el-dialog>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "EdenGardenerSelect",
  props: {
    shouldShow: {
      default: true,
    },
    placeholder: {
      type: String,
      default: "Select reason",
    },
    gardener: {
      type: [String, Number, Object],
      default: "Select gardener",
    },
    itemKey: {
      type: String,
      default: "id",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      setReason: "",
      rejecting: false,
      reasons: [
        "The merchant did not provide enough supporting documents",
        "The merchant provided an invalid address",
        "Merchant's product failed quality check",
        "Merchant failed onboarding test",
        "The merchant not within the coverage area/city",
      ],
    };
  },
  computed: {},
  methods: {
    rejectVendor() {
      this.rejecting = true;
      this.$store
        .dispatch(actions.VENDOR_APPROVAL, {
          id: this.$props.id,
          approval_status: "Rejected",
          rejection_reason: this.setReason,
        })
        .then((response) => {
          this.$message.success(response.data.message);

          console.log("happy");
          this.rejecting = false;
          this.$emit("success", this.$props.id);
          this.$emit("close");
        })
        .catch(() => {
          this.rejecting = false;
          console.log("sad");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
