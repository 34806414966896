<template>
  <div>
    <ProductBulkUpload
      :key="reRender"
      :show.sync="showFileUploadDialog"
      @close="closeDialog"
      @proceed="previewUpload"
      @save-bulk-upload="saveBulkUploadFile"
    ></ProductBulkUpload>
    <BulkUploadPreview
      :show.sync="showUploadPreviewDialog"
      :bulk-upload-file="BulkUploadFile"
      @close="showUploadPreviewDialog = false"
      @confirm="openPublishDialog"
      @go-back="goBack"
    ></BulkUploadPreview>
    <PublishBulkUploadDialog
      :show.sync="showpublichDialog"
      :data="data"
      @close="showpublichDialog = false"
      @go-back="goBackToPreview"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import BulkUploadPreview from "./vendors-product/BulkUploadPreview.vue";
import ProductBulkUpload from "./vendors-product/ProductBulkUpload.vue";
import PublishBulkUploadDialog from "./vendors-product/PublishBulkUploadDialog.vue";

export default {
  data() {
    return {
      reRender: 1,
      showpublichDialog: false,
      showFileUploadDialog: false,
      showUploadPreviewDialog: false,
      BulkUploadFile: null,
      data: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (newVal) {
      if (newVal) {
        this.showFileUploadDialog = true;
      } else {
        this.showFileUploadDialog = false;
      }
    },
  },
  methods: {
    closeDialog() {
      this.showFileUploadDialog = false;
      this.$emit("close");
      this.reRender = !this.reRender;
    },
    previewUpload() {
      this.showFileUploadDialog = false;
      this.showUploadPreviewDialog = true;
    },
    openPublishDialog(parsedData) {
      this.data = parsedData;
      this.showpublichDialog = true;
      this.showUploadPreviewDialog = false;
    },
    goBack() {
      this.showUploadPreviewDialog = false;
      this.showFileUploadDialog = true;
    },
    goBackToPreview() {
      this.showUploadPreviewDialog = true;
      this.showpublichDialog = false;
    },
    saveBulkUploadFile(file) {
      this.BulkUploadFile = file;
    },
  },
  components: { ProductBulkUpload, BulkUploadPreview, PublishBulkUploadDialog },
};
</script>
