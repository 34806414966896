import { render, staticRenderFns } from "./VendorDetailsProductsView.vue?vue&type=template&id=ace83304&scoped=true&"
import script from "./VendorDetailsProductsView.vue?vue&type=script&lang=js&"
export * from "./VendorDetailsProductsView.vue?vue&type=script&lang=js&"
import style0 from "./VendorDetailsProductsView.vue?vue&type=style&index=0&id=ace83304&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ace83304",
  null
  
)

export default component.exports