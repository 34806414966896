<template>
  <section>
    <eden-table-actions
      :title="`${vendorAddons?.data?.length || 0} Add-Ons`"
      @search="searchAddons"
    >
      <template
        slot="actions"
        v-if="
          allowAccessFor([
            'superadmin',
            'admin',
            'vendor_manager',
            'operations',
          ])
        "
      >
        <el-button type="primary" @click="command({ action: 'add' })">
          Add Add-On
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <!-- <pre>{{ vendorAddons }}</pre> -->

      <el-table
        :data="vendorAddons?.data"
        empty-text="No Add Ons"
        v-if="vendorAddons?.data?.length"
      >
        <el-table-column min-width="300">
          <template slot="header">
            <div class="table--header">
              <span>Name</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span>
              <div class="is-flex is-align-center gap-2">
                <div>
                  <img :src="scope.row.image_url" :alt="scope.row.name" />
                </div>
                <div>
                  {{ scope.row.name }}
                </div>
              </div>
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="eden-table-header"> Price </span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"> {{ formatPrice(scope.row.price) }} </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Inventory</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span :class="{ 'text-danger': scope.row.is_out_of_stock }">{{
              scope.row.is_unlimited
                ? "Unlimited"
                : scope.row.is_out_of_stock
                ? "Out of Stock"
                : `${scope.row.quantity} in stock`
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            allowAccessFor([
              'superadmin',
              'admin',
              'vendor_manager',
              'operations',
            ])
          "
          min-width="60"
        >
          <template slot-scope="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{
                    action: 'edit',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  >Edit add-on</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    action: 'delete',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  class="text-danger"
                  >Delete add-on</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No Add-Ons'" />
      <eden-pagination
        v-if="vendorAddons?.data?.length"
        :from="vendorAddons.from"
        :to="vendorAddons.to"
        :total="vendorAddons.total"
        :current-page.sync="page"
        :page-size="vendorAddons.per_page"
      />
    </template>

    <AddonForm
      :show.sync="addon.visibility"
      @success="$emit('success')"
      :action="addon.action"
      :product="singleAddon"
      :is-loading="editing"
    />
    <AddonDelete
      :show.sync="showDeleteAddon"
      :addon-id="addonId"
      @success="$emit('success')"
    />
  </section>
</template>

<script>
import AddonDelete from "./vendors-product/AddonDelete.vue";
import AddonForm from "./vendors-product/AddonForm.vue";

import details from "@/requests/vendors/details.js";

export default {
  components: { AddonForm, AddonDelete },
  props: {
    vendorAddons: {
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addonId: "",
      page: 1,
      addon: {
        action: "add",
        visibility: false,
        data: {},
      },
      editing: false,
      showDeleteAddon: false,
      singleAddon: {},
    };
  },
  methods: {
    command(command) {
      this.addonId = command.id;
      switch (command.action) {
        case "add":
          this.addon.action = "add";
          this.addon.visibility = true;
          break;
        case "edit":
          this.addon.action = "edit";
          this.getAddon();
          this.addon.visibility = true;
          break;
        case "delete":
          this.addon.action = "delete";
          this.showDeleteAddon = true;
          break;
      }
    },

    searchAddons(query) {
      this.$emit("query", query);
    },

    async getAddon() {
      try {
        this.editing = true;
        const params = {
          vendor_uuid: this.$route.params.id,
        };
        const response = await details.addon(this.addonId, params);
        if (response.data.status) {
          this.singleAddon = response.data.data;
        }
      } catch (err) {
        this.$message.error(err.response.data.message);
      } finally {
        this.editing = false;
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
</style>
