<template>
  <section>
    <eden-table-actions
      :title="`${vendorProducts?.data?.length || 0} Products`"
      @search="searchOrders"
    >
      <template slot="actions">
        <el-dropdown :placement="'bottom'" @command="command">
          <el-button type="plain">
            {{ formatText(selectedOption) }} products
            <i class="eden-icon-arrow-down ml-1"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="all"> All products </el-dropdown-item>
            <el-dropdown-item command="published">
              Published products
            </el-dropdown-item>
            <el-dropdown-item command="unpublished">
              Unpublished products
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          :placement="'bottom'"
          @command="productAction"
          v-if="
            allowAccessFor([
              'superadmin',
              'admin',
              'vendor_manager',
              'operations',
            ])
          "
        >
          <el-button type="primary">
            Add product
            <i class="eden-icon-arrow-down ml-1"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :command="{
                action: 'add',
              }"
            >
              Add Single Product
            </el-dropdown-item>
            <el-dropdown-item
              :command="{
                action: 'bulk',
              }"
            >
              Bulk Upload Products
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table
        :data="vendorProducts?.data"
        empty-text="No Products"
        v-if="vendorProducts?.data?.length"
      >
        <el-table-column min-width="300">
          <template slot="header">
            <div class="table--header">
              <span>Name</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              @click="
                productAction({
                  action: 'detail',
                  id: scope.row.uuid,
                  index: scope.$index,
                })
              "
            >
              <div class="is-flex is-align-center gap-2 cursor-pointer">
                <div>
                  <img :src="scope.row.image_url" />
                </div>
                <div>
                  {{ scope.row.name }}
                </div>
              </div>
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="eden-table-header"> Price </span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"> {{ formatPrice(scope.row.price) }} </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Status</span>
            </div>
          </template>
          <template slot-scope="scope">
            <el-tag :type="getTagType(scope.row.is_active, scope.row.is_draft)">
              <span>{{
                !scope.row.is_active
                  ? "Disabled"
                  : scope.row.is_draft
                  ? "Not Published"
                  : "Published"
              }}</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Inventory</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span :class="{ 'text-danger': scope.row.is_out_of_stock }">{{
              scope.row.is_unlimited
                ? "Unlimited"
                : scope.row.is_out_of_stock
                ? "Out of Stock"
                : `${scope.row.quantity} in stock`
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            allowAccessFor([
              'superadmin',
              'admin',
              'vendor_manager',
              'operations',
            ])
          "
          min-width="60"
        >
          <template slot-scope="scope">
            <el-dropdown @command="productAction" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{
                    action: 'update',
                    id: scope.row.uuid,
                    index: scope.$index,
                    unlimited: scope.row.is_unlimited,
                    quantity: scope.row.quantity,
                  }"
                  >Update inventory</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    action: 'edit',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  >Edit Product</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    action: 'publish',
                    id: scope.row.uuid,
                    index: scope.$index,
                    isDraft: scope.row.is_draft,
                  }"
                  >{{
                    scope.row.is_draft ? "Publish" : "Unpublish"
                  }}
                  Product</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    action: 'duplicate',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  >Duplicate Product</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    action: 'stock',
                    id: scope.row.uuid,
                    index: scope.$index,
                    inStock: scope.row.is_out_of_stock,
                  }"
                  >Mark as
                  {{ scope.row.is_out_of_stock ? "In Stock" : "Out of Stock" }}
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="scope.row.is_active"
                  :command="{
                    action: 'disable',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                >
                  Disable Product
                </el-dropdown-item>
                <el-dropdown-item
                  v-else
                  :command="{
                    action: 'enable',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                >
                  Enable Product
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{
                    action: 'delete',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  class="text-danger"
                  >Delete Product</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No Products'" />
      <eden-pagination
        v-if="vendorProducts?.data?.length"
        :from="vendorProducts.from"
        :to="vendorProducts.to"
        :total="vendorProducts.total"
        :current-page.sync="page"
        :page-size="vendorProducts.per_page"
      />
    </template>

    <ProductForm
      :show.sync="product.visibility"
      :product-data="vendorProducts.data"
      :tags="tags"
      :action="product.action"
      :loading="editing"
      :product="singleProduct"
      :vendor-addons="vendorAddons"
      @product-created="getProducts"
      @success="$emit('success')"
    />
    <product-delete
      :show.sync="showDeleteProduct"
      :product-id="productId"
      @success="getProducts"
    />
    <UpdateInventory
      :show.sync="showUpdateInventory"
      :unlimited="unlimited"
      :quantity="quantity"
      :product-id="productId"
      @success="getProducts"
    />
    <PublishProduct
      :show.sync="showPublishProduct"
      :product-id="productId"
      :is-draft="isDraft"
      @success="getProducts"
    />
    <MarkStock
      :show.sync="showMarkStock"
      :product-id="productId"
      :stock="inStock"
      @success="getProducts"
    />
    <ProductDetail
      :show.sync="showProductDetail"
      :overview="overview"
      :loading="isLoading"
      :product-id="productId"
      @edit-product="
        productAction({ action: 'edit', id: overview?.product?.uuid })
      "
      @delete-product="
        productAction({ action: 'delete', id: overview?.product?.uuid })
      "
    />
    <DisableProduct
      :show.sync="showDisableProduct"
      :product-id="productId"
      :vendor-id="vendorId"
      @success="getProducts"
    />

    <BulkUploadContainer
      :show.sync="showBulkUpload"
      @close="showBulkUpload = false"
      @success="getProducts"
    />
  </section>
</template>

<script>
import ProductForm from "./vendors-product/ProductForm.vue";
import ProductDelete from "./vendors-product/ProductDelete.vue";
import details from "@/requests/vendors/details.js";
import UpdateInventory from "./vendors-product/UpdateInventory.vue";
import PublishProduct from "./vendors-product/PublishProduct.vue";
import MarkStock from "./vendors-product/MarkStock.vue";
import ProductDetail from "./vendors-product/ProductDetail.vue";
import product from "@/requests/products/product";
import DisableProduct from "../../Products/DisableProduct.vue";
import BulkUploadContainer from "./BulkUploadContainer.vue";

export default {
  components: {
    ProductForm,
    ProductDelete,
    UpdateInventory,
    PublishProduct,
    MarkStock,
    ProductDetail,
    DisableProduct,
    BulkUploadContainer,
  },
  props: {
    tags: {
      default: {},
    },
    vendorAddons: {
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      vendorProducts: {},
      selectedOption: "all",
      productId: "",
      page: 1,
      product: {
        action: "add",
        visibility: false,
        data: {},
      },
      searchQuery: "",
      singleProduct: [],
      overview: [],
      editing: false,
      isLoading: false,
      showDeleteProduct: false,
      showUpdateInventory: false,
      showPublishProduct: false,
      showProductDetail: false,
      showDisableProduct: false,
      showMarkStock: false,
      showBulkUpload: false,
      unlimited: null,
      quantity: null,
      isDraft: null,
      inStock: null,
      duplicatedProductId: "",
    };
  },
  watch: {
    page: {
      handler: "getProducts",
      immediate: true,
    },
  },
  created() {
    this.getProducts();
  },
  computed: {
    vendorId() {
      return this.$route.params.id;
    },
  },
  methods: {
    command(command) {
      switch (command) {
        case "all":
          this.selectedOption = "all";
          break;
        case "published":
          this.selectedOption = "published";
          break;
        case "unpublished":
          this.selectedOption = "unpublished";
          break;
        default:
          break;
      }
      this.page = 1;
      this.getProducts();
    },
    productAction(command) {
      this.productId = command.id;
      this.unlimited = command.unlimited;
      this.quantity = command.quantity;
      this.isDraft = command.isDraft;
      this.inStock = command.inStock;
      switch (command.action) {
        case "add":
          this.product.action = "add";
          this.product.visibility = true;
          break;
        case "edit":
          this.getSingleProduct();
          this.product.action = "edit";
          this.product.visibility = true;
          if (this.showProductDetail) {
            this.showProductDetail = false;
          }
          break;
        case "delete":
          this.product.action = "delete";
          this.showDeleteProduct = true;
          break;
        case "update":
          this.product.action = "update";
          this.showUpdateInventory = true;
          break;
        case "publish":
          this.product.action = "publish";
          this.showPublishProduct = true;
          break;
        case "stock":
          this.product.action = "stock";
          this.showMarkStock = true;
          break;
        case "duplicate":
          this.product.action = "edit";
          this.duplicateProduct();
          this.product.visibility = true;
          break;
        case "disable":
          this.showDisableProduct = true;
          break;
        case "enable":
          this.enableProduct();
          break;
        case "detail":
          this.product.action = "detail";
          this.getOverview();
          this.showProductDetail = true;
          break;
        case "bulk":
          this.product.action = "bulk";
          this.showBulkUpload = true;
          break;
      }
    },

    enableProduct() {
      product
        .enable(this.vendorId, this.productId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getProducts();
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },

    async duplicateProduct() {
      const payload = { product_id: this.productId };
      await details
        .duplicate(this.vendorId, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.productId = response.data.data.uuid;
            this.getSingleProduct();
            this.getProducts();
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    searchOrders(query) {
      this.searchQuery = query;
      this.page = 1;
      this.getProducts();
    },
    getTagType(isActive, isDraft) {
      return !isActive ? "info" : isDraft ? "info" : "success";
    },
    async getSingleProduct() {
      try {
        this.editing = true;
        const response = await details.singleProduct(
          this.vendorId,
          this.productId,
        );

        if (response.data.status) {
          this.singleProduct = response.data.data;
        }
      } catch (err) {
        this.$message.error(err.response.data.message);
      } finally {
        this.editing = false;
      }
    },
    async getOverview() {
      try {
        this.isLoading = true;
        const params = {
          vendor_uuid: this.vendorId,
        };
        const response = await details.productOverview(this.productId, params);
        if (response.data.status) {
          this.overview = response.data.data;
        }
      } catch (err) {
        this.$message.error(err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getProducts() {
      this.loading = true;
      await details
        .products(this.vendorId, {
          search: this.searchQuery || "",
          filter: this.selectedOption || "all",
          page: this.page,
        })
        .then((response) => {
          if (response.data.status) {
            this.vendorProducts = response.data.data;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterProduct(command, query, page) {
      this.searchQuery = query;
      this.selectedOption = command;
      this.page = page;

      this.getProducts();
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
</style>
