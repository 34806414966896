<template>
  <el-dialog
    :title="`Mark product as ${stockText}`"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <p v-if="!stock">
      By marking this product as ‘Out of Stock’, customers will still see it on
      your storefront but will not be able to place orders for it.
    </p>
    <el-form :model="form" label-position="top" v-else>
      <el-row type="flex" :gutter="15" class="is-flex-wrap">
        <el-col :md="form.is_unlimited ? 24 : 12">
          <el-form-item label="Quantity Available" prop="unlimited">
            <el-select v-model="form.is_unlimited" placeholder="quantity">
              <el-option label="Limited" :value="false" />
              <el-option label="Unlimited" :value="true" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="12" v-if="!form.is_unlimited" class="mt-1">
          <el-form-item label=" " prop="quantity" :rules="validateField()">
            <el-input
              v-model="form.quantity"
              type="number"
              placeholder="Insert available quantity here"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        :type="`${stock ? 'primary' : 'danger'}`"
        :loading="loading"
        @click="markStock"
      >
        Mark as {{ stockText }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import details from "@/requests/vendors/details.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    stock: {
      type: Boolean,
      default: false,
    },
    productId: String,
  },

  data() {
    return {
      form: {
        is_unlimited: true,
        quantity: 0,
      },
      loading: false,
    };
  },

  computed: {
    stockText() {
      return `${this.stock ? "In stock" : "Out of Stock"}`;
    },
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        is_unlimited: true,
        quantity: 0,
      };
    },
    async markStock() {
      this.loading = true;
      const payload = {
        product_ids: [this.productId],
        in_stock: this.stock ? true : false,
        is_unlimited: this.form.is_unlimited,
        quantity: this.form.quantity,
      };
      await details
        .updateInventory(this.$route.params.id, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.productId);
            this.closeModal();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
