<template>
  <section>
    <el-drawer :visible.sync="setShow" direction="rtl" @close="closeEvent">
      <template slot="title">
        <span class="text-grey-primary">Product Detail</span>
      </template>

      <div class="el-drawer--content" v-loading="isLoading">
        <div class="el-drawer--content__body">
          <div
            class="is-flex is-flex-column justify-center align-center gap-1 mb-4"
          >
            <img
              class="product-img"
              :src="overview?.product?.image_url"
              :alt="overview?.product?.name"
            />

            <h6>{{ overview?.product?.name }}</h6>

            <p>{{ formatPrice(overview?.product?.price) }}</p>

            <div>
              <el-tag :type="getTagType(overview?.product?.is_draft)">
                {{
                  overview?.product?.is_draft ? "Not Published" : "Published"
                }}
              </el-tag>
            </div>

            <div class="is-flex justify-center align-center">
              <el-button icon="eden-icon-edit" @click="$emit('edit-product')">
                Edit Product
              </el-button>

              <el-dropdown :placement="'bottom'" @command="command">
                <el-button type="plain" icon="eden-icon-more">
                  More Actions
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="delete" class="text-danger">
                    Delete product
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>

          <div class="is-flex justify-center align-center w-100">
            <el-tabs v-model="tab" class="custom-tabs">
              <el-tab-pane
                label="Overview"
                name="overview"
                class="is-flex is-flex-column gap-3"
              >
                <el-row type="flex">
                  <el-col>
                    <eden-information-card>
                      <template slot="content">
                        <el-table
                          :class="'general'"
                          :data="info"
                          :show-header="false"
                        >
                          <el-table-column>
                            <template slot-scope="scope">
                              <template>
                                <el-table
                                  :class="'inner-table'"
                                  :data="scope.row.data"
                                  :show-header="false"
                                >
                                  <el-table-column class="none">
                                    <template slot-scope="scope">
                                      <span class="label font-xsm">{{
                                        scope.row.label
                                      }}</span>
                                      <p class="text" style="font-weight: 700">
                                        {{ scope.row.text }}
                                      </p>
                                    </template>
                                  </el-table-column>
                                </el-table>
                              </template>
                            </template>
                          </el-table-column></el-table
                        >
                      </template>
                    </eden-information-card>
                  </el-col>
                </el-row>
                <el-row type="flex" v-if="overview?.product?.description">
                  <el-col>
                    <p class="small-header">Description</p>
                    <p>{{ overview?.product?.description }}</p>
                  </el-col>
                </el-row>
                <el-row type="flex">
                  <el-col>
                    <p class="small-header">Quantity Available</p>
                    <p>
                      {{
                        overview?.product?.is_unlimited
                          ? "Unlimited"
                          : overview?.product?.is_out_of_stock
                          ? "Out of Stock"
                          : `${overview?.product?.quantity} in stock`
                      }}
                    </p>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  v-if="overview?.product?.categories?.length"
                >
                  <el-col>
                    <p class="small-header">Categories</p>
                    <p>
                      {{
                        overview?.product?.categories
                          .map((category) => category.name)
                          .join(", ")
                      }}
                    </p>
                  </el-col>
                </el-row>
                <el-row type="flex" v-if="overview?.product?.tags?.length">
                  <el-col>
                    <p class="small-header">Tags</p>
                    <el-tag
                      v-for="tag in overview?.product?.tags"
                      :key="tag.id"
                    >
                      {{ tag.name }}
                    </el-tag>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="Add-Ons" name="addons">
                <div v-if="addons?.length">
                  <p class="mid-header">{{ addons?.length }} Add-Ons</p>
                  <eden-information-card
                    v-for="addon in addons"
                    :key="addon.id"
                  >
                    <template slot="content">
                      <div class="is-flex align-center gap-2 px-2 pb-2">
                        <img
                          class="addon-img"
                          :src="addon.image_url"
                          :alt="addon.name"
                        />
                        <div>
                          <p>{{ addon.name }}</p>
                          <p>{{ formatPrice(addon.price) }}</p>
                        </div>
                      </div>
                    </template>
                  </eden-information-card>
                </div>

                <div
                  class="text-center is-flex is-flex-column justify-center align-center gap-1 mt-5"
                  v-else
                >
                  <h5>You haven’t added any add-ons to this product.</h5>
                  <p class="small-header">
                    Increase your sales by offering additional items that your
                    customers can get with this product.
                  </p>
                  <div class="text-center is-flex justify-center align-center">
                    <el-button type="primary" @click="$emit('edit-product')">
                      Add Add-On
                    </el-button>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Reviews" name="reviews">
                <div v-if="reviews.feedback?.length">
                  <div class="is-flex is-flex-column align-center gap-1 mb-3">
                    <div class="is-flex gap-1">
                      <h6>{{ reviews.average_rating }}</h6>
                      <span class="small-header mt-1">
                        ({{
                          getRatingText(
                            Number(reviews.average_rating.toFixed(0)),
                          )
                        }})
                      </span>
                    </div>
                    <el-rate
                      :value="reviews.average_rating"
                      disabled
                      text-color="#ff9900"
                    />
                    <p>
                      Overall rating based on
                      {{ reviews.total_count }} ratings
                    </p>
                  </div>

                  <div class="is-flex is-flex-column align-center mb-3">
                    <div
                      v-for="rating in Object.keys(reviews.breakdown)"
                      :key="rating"
                      class="w-100 mb-1 is-flex gap-1"
                    >
                      <span> {{ rating }} </span>
                      <el-progress
                        class="w-100"
                        :percentage="
                          percent(
                            getCountForRating(parseInt(rating)),
                            reviews.total_count,
                          )
                        "
                        :stroke-width="6"
                        :show-text="true"
                        :format="format"
                      />
                    </div>
                  </div>

                  <eden-information-card
                    v-for="review in reviews.feedback"
                    :key="review.id"
                  >
                    <template slot="content">
                      <div
                        class="is-flex is-flex-column justify-between gap-1 px-2 pb-2"
                      >
                        <div class="is-flex justify-between">
                          <p>{{ formatName(review.customer_name) }}</p>
                          <p class="small-header">
                            {{ formatDateAndTime(review.created_at) }}
                          </p>
                        </div>
                        <div class="is-flex gap-1">
                          <el-rate
                            :value="review.rating"
                            disabled
                            text-color="#ff9900"
                          />
                          <span class="small-header">{{
                            getRatingText(review.rating)
                          }}</span>
                        </div>
                        <p>{{ review.description }}</p>
                        <div>
                          <el-button plain @click="viewOrder(review)">
                            View Order
                          </el-button>
                        </div>
                      </div>
                    </template>
                  </eden-information-card>
                </div>
                <div
                  v-else
                  class="text-center is-flex is-flex-column justify-center align-center gap-1 mt-5"
                >
                  <h5>There are no reviews for this product yet.</h5>
                  <p>
                    Keep providing top-notch products and services to encourage
                    your customers to share their experiences.
                  </p>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <!-- <pre>{{ reviews }}</pre> -->
      </div>
      <div class="el-drawer--footer is-align-center w-100"></div>
    </el-drawer>
  </section>
</template>

<script>
import details from "@/requests/vendors/details.js";
export default {
  name: "ProductDetail",
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    overview: {
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    productId: String,
  },
  data() {
    return {
      tab: "overview",
      isLoading: false,
      addons: {},
      reviews: {},
    };
  },
  watch: {
    tab: {
      handler(newValue) {
        if (newValue === "addons") {
          this.getAddons();
        }
        if (newValue === "reviews") {
          this.getReviews();
        }
      },
      immediate: true,
    },
    loading: {
      handler(newValue) {
        this.isLoading = newValue;
      },
      immediate: true,
    },
  },
  onMounted() {
    this.tab = "overview";
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    info() {
      return [
        {
          data: [
            {
              label: "Total Sales",
              text: this.overview?.total_sales ?? 0,
            },
            {
              label: "Total Revenue",
              text: this.formatPrice(this.overview?.total_revenue ?? 0),
            },
          ],
        },
      ];
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.tab = "overview";
    },
    async getAddons() {
      try {
        this.isLoading = true;
        const params = {
          vendor_uuid: this.$route.params.id,
        };
        const response = await details.productAddons(this.productId, params);
        if (response.data.status) {
          this.addons = response.data.data;
        }
      } catch (err) {
        this.$message.error(err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getReviews() {
      try {
        this.isLoading = true;
        const params = {
          vendor_uuid: this.$route.params.id,
        };
        const response = await details.productReviews(this.productId, params);
        if (response.data.status) {
          this.reviews = response.data.data;
        }
      } catch (err) {
        this.$message.error(err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    getTagType(isDraft) {
      return isDraft ? "info" : "success";
    },
    command(command) {
      switch (command) {
        case "delete":
          this.$emit("delete-product");
          this.closeEvent();
          break;
      }
    },
    viewOrder(review) {
      this.closeEvent();
      this.$router.push({
        name: `ordersAndSchedule.details`,
        params: { id: review.order_uuid },
      });
    },
    getRatingText(rating) {
      switch (rating) {
        case 5:
          return "Excellent";
        case 4:
          return "Satisfied";
        case 3:
          return "Average";
        case 2:
          return "Poor";
        case 1:
          return "Very Poor";
        default:
          return "";
      }
    },
    avgRatings(feedbacks) {
      const total = feedbacks
        .map((feedback) => feedback.rating)
        .reduce((tRatings, currRating) => tRatings + currRating, 0);
      return total / feedbacks.length;
    },
    getCountForRating(rating) {
      const count = this.reviews.feedback.filter(
        (feedback) => feedback.rating === rating,
      ).length;
      return count;
    },
    format(percentage) {
      return `${percentage.toFixed(1)}%`;
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1.5rem;
}

h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.line-divider {
  border-bottom: 1px solid #f0f4f2;
  margin: 32px 0px;
}

.product-img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}
.addon-img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.small-header {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
  color: var(--eden-grey-tertiary);
}
.mid-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 16px;
  color: var(--eden-grey-primary);
}

.custom-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

:deep .el-tabs__nav {
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--eden-grey-septenary);
}

:deep .el-tabs__item {
  flex: 1;
  text-align: center;
  position: relative;
}

:deep .el-tabs__item.is-active::after {
  content: "";
  height: 2px;
  background-color: var(--eden-green-primary);
  position: absolute;
  bottom: 0;
  left: 10;
  width: 100%;
}
</style>
