<template>
  <eden-confirm-dialog
    title="Delete this product?"
    button-type="danger"
    button-text="Delete product"
    :show.sync="shouldShow"
    @confirm="deleteProduct"
  >
    <p>
      If you delete this product, all data associated with it will be
      permanently erased, but past orders and customers will not be affected.
    </p>
  </eden-confirm-dialog>
</template>

<script>
import details from "@/requests/vendors/details";

export default {
  name: "ProductDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productId: String,
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    async deleteProduct() {
      const payload = {
        product_ids: [this.productId],
        vendor_uuid: this.$route.params.id,
      };
      await details
        .deleteProduct(this.$route.params.id, { data: payload })
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.productId);
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
