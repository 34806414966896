<template>
  <el-dialog
    :title="`${formatText(action)} an Add-On`"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <el-form
      :model="form"
      label-position="top"
      ref="form"
      v-loading="isLoading"
    >
      <el-row type="flex">
        <el-col :md="24" class="is-flex is-flex-column align-center">
          <eden-image-upload
            :image-url.sync="form.image_url"
            :image-text="'image'"
            :image-folder="'products'"
          />
          <p class="text-center mt-1">
            Use a square image (1:1 aspect ratio) for best display
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Name" prop="name" :rules="validateField()">
            <el-input v-model="form.name" type="text"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="15" class="is-flex-wrap">
        <el-col :md="form.use_discounted_price ? 12 : 24">
          <el-form-item label="Price" prop="price" :rules="validateField()">
            <el-input
              v-model="form.price"
              type="number"
              :placeholder="LOCATION === 'NG' ? '₦' : 'KES'"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12" v-if="form.use_discounted_price">
          <el-form-item label="Discounted Price" prop="discountPrice">
            <el-input
              v-model="form.discounted_price"
              type="number"
              :placeholder="LOCATION === 'NG' ? '₦' : 'KES'"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="100">
          <el-checkbox
            v-model="form.use_discounted_price"
            class="is-flex is-align-self-start"
          >
            <div>
              <p style="font-weight: 500; font-size: 14px">
                Display a discounted price
              </p>
              <p class="text-wrap">
                Select this if you would like to offer your product at a reduced
                price. Customers will see both the original and discounted
                price.
              </p>
            </div>
          </el-checkbox>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="15" class="is-flex-wrap">
        <el-col :md="form.is_unlimited ? 24 : 12">
          <el-form-item label="Quantity Available" prop="unlimited">
            <el-select v-model="form.is_unlimited" placeholder="quantity">
              <el-option label="Limited" :value="false" />
              <el-option label="Unlimited" :value="true" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="12" v-if="!form.is_unlimited" class="mt-1">
          <el-form-item label=" " prop="quantity" :rules="validateField()">
            <el-input
              v-model="form.quantity"
              type="number"
              placeholder="Insert available quantity here"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="loading"
        :disabled="isDisabled"
        @click="createAddOn"
      >
        Add Add-On
      </el-button>
      <el-button
        v-else
        type="primary"
        :loading="loading"
        :disabled="isDisabled"
        @click="updateAddOn"
      >
        Edit Add-On
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import details from "@/requests/vendors/details.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    product: {
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        name: "",
        price: 0,
        image_url: "",
        discounted_price: 0,
        use_discounted_price: false,
        is_unlimited: true,
        quantity: 0,
      },
      loading: false,
      LOCATION: localStorage.getItem("eden-crm-location"),
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isDisabled() {
      return (
        !this.form.image_url &&
        !this.form.name &&
        !this.form.price &&
        this.form.is_unlimited
      );
    },
    vendorId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.form = {
      image_url: "",
      name: "",
      price: null,
      discounted_price: 0,
      use_discounted_price: false,
      is_unlimited: true,
      quantity: 0,
    };
  },

  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit") {
          this.setData();
        }
      }
    },
    product: {
      handler: "setData",
    },
  },

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        image_url: "",
        name: "",
        price: null,
        discounted_price: 0,
        use_discounted_price: false,
        is_unlimited: true,
        quantity: 0,
      };
    },
    createAddOn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        const payload = {
          ...this.form,
          vendor_uuid: this.vendorId,
        };

        details
          .createAddons(this.vendorId, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeModal();
            }
          })
          .catch((err) => {
            this.$message.error(err.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
        return true;
      });
    },
    updateAddOn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        const payload = {
          ...this.form,
          vendor_uuid: this.vendorId,
        };
        console.log(this.product.uuid, "product id");

        details
          .updateAddons(this.product.uuid, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeModal();
            }
          })
          .catch((err) => {
            this.$message.error(err.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
        return true;
      });
    },
    setData() {
      this.form = {
        name: this.product.name,
        image_url: this.product.image_url,
        price: this.product.price,
        is_unlimited: this.product.is_unlimited,
        discounted_price: this.product.discounted_price,
        use_discounted_price: this.product.use_discounted_price,
        quantity: this.product.quantity,
      };
    },
  },
};
</script>
