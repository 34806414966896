<template>
  <el-dialog
    :title="`Edit Operation Details`"
    :visible="shouldShow"
    @close="closeModal"
  >
    <el-form :model="form" label-position="top">
      <el-row>
        <el-col>
          <h6 class="font-lg text-bold mb-3">Operation Mode</h6>
          <el-form-item>
            <el-radio-group
              v-model="form.operation_mode"
              class="is-flex gap-2 radio-box"
            >
              <div class="is-flex gap-1">
                <div>
                  <el-radio label="on_demand" :class="radioCards" class="h-100">
                    <p class="text-bold">Instant Delivery</p>
                    <span class="text-wrap font-sm">
                      Allow customers to place orders for immediate delivery.
                    </span>
                  </el-radio>
                </div>
                <div>
                  <el-radio label="pre_order" :class="radioCards" class="h-100">
                    <p class="text-bold">Pre-Order</p>
                    <span class="text-wrap font-sm">
                      Allow customers to place orders for the next available
                      date only.
                    </span>
                  </el-radio>
                </div>
              </div>
              <div>
                <el-radio label="both" :class="radioCards">
                  <p class="text-bold">Both</p>
                  <p class="text-wrap font-sm">
                    Allow customers to place instant orders and pre-orders.
                  </p>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h6 class="font-lg text-bold mb-1">Preparation time</h6>
          <el-form-item
            label="The is the estimated duration required for getting each order ready, usually based on an average or maximum timeframe."
          >
            <div class="counter">
              <el-input v-model.number="form.prep_time" v-number type="text" />
              <el-button
                :type="'control'"
                icon="el-icon-minus"
                :disabled="form.prep_time === 1"
                @click="form.prep_time--"
              />
              <el-button
                :type="'control'"
                icon="el-icon-plus"
                :disabled="form.prep_time === 300"
                @click="form.prep_time++"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :loading="loading" @click="update">
        Save Operation Details
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import details from "@/requests/vendors/details.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      default: () => ({}),
    },
  },

  data() {
    return {
      form: {
        operation_mode: "",
        prep_time: null,
      },
      loading: false,
      radioCards:
        "is-flex gap-1 is-flex-reverse border border-grey-septenary rounded px-1 py-1",
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isDisabled() {
      return !this.form.operation_mode && !this.form.prep_time;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.operation_mode =
          this.data?.business_information?.operation_mode;
        this.form.prep_time = this.data?.business_information?.prep_time;
      }
    },
  },

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        operation_mode: "",
        prep_time: null,
      };
    },
    update() {
      // this.$refs.form.validate(async (valid) => {
      //   if (!valid) {
      //     return false;
      //   }

      this.loading = true;

      details
        .vendorDetails(this.$route.params.id, this.form)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.loading = false;
            this.closeModal();
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
      return true;
      // });
    },
  },
};
</script>

<style scoped>
p {
  font-weight: 400;
  font-size: 14px;
}

:deep .el-dialog {
  width: 60% !important;
  @media (max-width: 1200px) {
    width: 60% !important;
  }
}
.radio-box {
  @media (max-width: 820px) {
    flex-wrap: wrap !important;
  }
}

.counter {
  display: flex;
  align-items: center;
  width: 100%;

  .el-input {
    margin: 0 10px 0 0;

    &__inner {
      height: 28px;
      line-height: 28px;
      text-align: center;
    }
  }
}
</style>
