var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('eden-table-actions',{attrs:{"title":`${_vm.vendorAddons?.data?.length || 0} Add-Ons`},on:{"search":_vm.searchAddons}},[(
        _vm.allowAccessFor([
          'superadmin',
          'admin',
          'vendor_manager',
          'operations',
        ])
      )?_c('template',{slot:"actions"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.command({ action: 'add' })}}},[_vm._v(" Add Add-On ")])],1):_vm._e()],2),(_vm.loading)?_c('eden-loader'):[(_vm.vendorAddons?.data?.length)?_c('el-table',{attrs:{"data":_vm.vendorAddons?.data,"empty-text":"No Add Ons"}},[_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_c('div',{staticClass:"is-flex is-align-center gap-2"},[_c('div',[_c('img',{attrs:{"src":scope.row.image_url,"alt":scope.row.name}})]),_c('div',[_vm._v(" "+_vm._s(scope.row.name)+" ")])])])]}}],null,false,2389022233)},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Name")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(_vm.formatPrice(scope.row.price))+" ")])]}}],null,false,1810361186)},[_c('template',{slot:"header"},[_c('span',{staticClass:"eden-table-header"},[_vm._v(" Price ")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:{ 'text-danger': scope.row.is_out_of_stock }},[_vm._v(_vm._s(scope.row.is_unlimited ? "Unlimited" : scope.row.is_out_of_stock ? "Out of Stock" : `${scope.row.quantity} in stock`))])]}}],null,false,1610971475)},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Inventory")])])])],2),(
          _vm.allowAccessFor([
            'superadmin',
            'admin',
            'vendor_manager',
            'operations',
          ])
        )?_c('el-table-column',{attrs:{"min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                  action: 'edit',
                  id: scope.row.uuid,
                  index: scope.$index,
                }}},[_vm._v("Edit add-on")]),_c('el-dropdown-item',{staticClass:"text-danger",attrs:{"command":{
                  action: 'delete',
                  id: scope.row.uuid,
                  index: scope.$index,
                }}},[_vm._v("Delete add-on")])],1)],1)]}}],null,false,2669709766)}):_vm._e()],1):_c('eden-content-empty',{attrs:{"text":'No Add-Ons'}}),(_vm.vendorAddons?.data?.length)?_c('eden-pagination',{attrs:{"from":_vm.vendorAddons.from,"to":_vm.vendorAddons.to,"total":_vm.vendorAddons.total,"current-page":_vm.page,"page-size":_vm.vendorAddons.per_page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()],_c('AddonForm',{attrs:{"show":_vm.addon.visibility,"action":_vm.addon.action,"product":_vm.singleAddon,"is-loading":_vm.editing},on:{"update:show":function($event){return _vm.$set(_vm.addon, "visibility", $event)},"success":function($event){return _vm.$emit('success')}}}),_c('AddonDelete',{attrs:{"show":_vm.showDeleteAddon,"addon-id":_vm.addonId},on:{"update:show":function($event){_vm.showDeleteAddon=$event},"success":function($event){return _vm.$emit('success')}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }