const useFileSystemMixin = {
  data() {
    return {
      files: [],
      uploading: false,
      uploadConfig: {
        cloudinaryUrl: "https://api.cloudinary.com/v1_1/eden-life-inc/upload",
        cloudinaryPresetUpload: "zt3wbrxu",
      },
      byteScaleConfig: {
        apiKey: process.env.VUE_APP_BYTESCALE_API_KEY,
      },
    };
  },
  methods: {
    addFiles(newFiles) {
      let newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file))
        .filter((file) => !this.fileExists(file.id));
      this.files = this.files?.concat(newUploadableFiles);
    },
    generateBlob(newFile) {
      return URL.createObjectURL(newFile[0]);
    },
    fileExists(otherId) {
      return this.files?.some(({ id }) => id === otherId);
    },
    removeFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) this.files.splice(index, 1);
    },
    formatFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }

      const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      );

      return bytes.toFixed(dp) + " " + units[u];
    },
  },
};

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}

export default useFileSystemMixin;
