<template>
  <el-dialog
    :title="'Bulk Upload Products'"
    :visible="show"
    @close="$emit('close')"
    append-to-body
    :width="'60% !important'"
  >
    <eden-alert type="warning" :icon="false">
      In Excel, organise your product data into the following columns: <br />
      <strong>Image URL, Name, Description, Price, Category, Quantity.</strong
      ><br />
      The column names and order must match exactly for the import to work.<br />
      Once you have formatted the file, save it as either a .csv or .xls file
      and upload it below. <br />
      Note: Images embedded in Google drive links are not supported and would
      not work!!
    </eden-alert>
    <section class="gh-container--form__header">
      <div class="my-4" v-if="!form.file">
        <eden-drag-and-drop
          :types="[
            'text/csv',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]"
          :error="`Please select a valid file`"
          class="drop-area border border-dashed border-grey-quinary rounded"
          @files-dropped="handleFilesDropped"
          #default="{ dropZoneActive }"
        >
          <div v-if="uploading" class="is-flex is-flex-column is-align-center">
            <div>Uploading...</div>
          </div>
          <div
            v-if="dropZoneActive && !uploading"
            class="is-flex is-flex-column is-align-center"
          >
            <div>Drop Them</div>
          </div>
          <div
            v-else-if="!uploading && !dropZoneActive"
            class="is-flex is-flex-column is-align-center"
          >
            <i class="eden-icon-upload font-xl mb-1"></i>
            <div>
              Drag and drop or
              <span class="text-primary pointer" @click="openFilePicker"
                >click to upload file</span
              >
            </div>
            <div class="text-grey-tertiary font-sm mt-1">
              Must be a .CSV or .XLS (Excel) file.
            </div>

            <input
              @change="handleFileChange"
              ref="fileInput"
              type="file"
              accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              class="is-hidden"
            />
          </div>
        </eden-drag-and-drop>
      </div>
      <div class="my-4" v-else>
        <div
          class="border dropped-item border-dashed border-grey-quinary rounded px-3 py-3"
        >
          <div class="is-flex is-align-center">
            <img
              :src="getImage('attachments/csv.svg')"
              class="doc-image mr-1"
              alt="document"
            />
            <span class="text-bold is-inline-block" v-if="files[0]">
              {{ files[0].file.name }}
              <span class="ml-1 text-normal"
                >({{ formatFileSize(files[0].file.size) }})</span
              >
            </span>
            <span class="text-bold is-inline-block" v-else>
              {{ form.doc_name }}
            </span>
            <el-button
              type="text"
              class="text-danger ml-auto"
              @click="removeDocument"
            >
              <el-icon
                :size="17"
                style="transform: rotate(45deg); margin-right: 4px"
                ><circle-plus /></el-icon
              >Remove
            </el-button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <strong class="d-block mb-1">Need help formatting your file?</strong>
      <div
        class="d-block text-primary mb-1 cursor-pointer"
        @click="downloadProductTemplates('excel')"
      >
        Download a .xls (Excel) template here
      </div>
      <div
        class="text-primary cursor-pointer"
        @click="downloadProductTemplates('csv')"
      >
        Download a .csv template here
      </div>
    </section>

    <section class="is-flex mt-4 justify-end">
      <el-button @click="$emit('close')">Cancel</el-button>
      <el-button
        type="primary"
        @click="$emit('proceed')"
        :disabled="disableButton"
        >Continue</el-button
      >
    </section>
  </el-dialog>
</template>

<script>
import useFileSystemMixin from "@/mixins/file";
import Papa from "papaparse";
import * as XLSX from "xlsx";
export default {
  name: "ProductBulkUpload",
  mixins: [useFileSystemMixin],
  data() {
    return {
      form: {
        file: "",
      },
      files: [],
      uploading: false,
      sampleData: [
        {
          name: "Asun rice",
          quantity: 10,
          category: "Food",
          price: 2000,
          description: "This is a description of this product.",
          image_url:
            "https://res.cloudinary.com/eden-life-inc/image/upload/q_auto,f_auto/v1661773323/lighthouse/mealImages/skwobqaigqkcvnjmn09q.jpg",
        },
        {
          name: "Jollof rice",
          quantity: 10,
          category: "Food",
          price: 2000,
          description: "This is a description of this product.",
          image_url:
            "https://res.cloudinary.com/eden-life-inc/image/upload/q_auto,f_auto/v1661773323/lighthouse/mealImages/skwobqaigqkcvnjmn09q.jpg",
        },
      ],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disableButton() {
      return !this.form.file;
    },
  },
  methods: {
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.addFiles(event.target.files);
      this.form.file = event.target.files[0];
      this.$emit("save-bulk-upload", this.form.file);
    },
    removeDocument() {
      this.removeFile(this.form.file);
      this.form.file = "";
    },
    handleFilesDropped(newFiles) {
      this.addFiles(newFiles);
      this.form.file = newFiles[0];
      this.$emit("save-bulk-upload", this.form.file);
    },

    downloadProductTemplates(type) {
      const dummyData = this.sampleData.map((val) => ({
        "Name": val.name,
        "Image URL": val.image_url,
        "Category": val.category,
        "Price": val.price,
        "Description": val.description,
        "Quantity": val.quantity,
      }));

      if (type === "csv") {
        this.downloadCSVTemplate(dummyData);
      } else {
        this.downloadExcelTemplate(dummyData);
      }
    },

    downloadCSVTemplate(data) {
      const csv = Papa.unparse(data);
      const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const csvURL = window.URL.createObjectURL(csvData);
      const testLink = document.createElement("a");

      testLink.href = csvURL;
      testLink.setAttribute("download", "ProductBulkUploadTemplate.csv");
      testLink.click();
    },

    downloadExcelTemplate(data) {
      /* generate worksheet from state */
      const ws = XLSX.utils.json_to_sheet(data);
      /* create workbook and append worksheet */
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      /* export to XLSX */
      XLSX.writeFileXLSX(wb, "ProductBulkUploadTemplate.xlsx");
    },
  },
};
</script>

<style lang="scss">
.drop-area {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 0px;
}

.doc-image {
  height: 25px;
  width: 25px;
}
</style>
