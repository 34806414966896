<template>
  <div>
    <eden-information-card :title="'Socials'">
      <template slot="content">
        <el-table
          :class="'general'"
          :data="socials"
          :show-header="false"
          :span-method="arraySpanMethod"
        >
          <el-table-column>
            <template slot-scope="scope">
              <!-- <template v-if="scope.$index < 2">
                {{ scope.$index }}
                <span class="label font-xsm">{{ scope.row.label }}</span>
                <p class="text font-sm">
                  {{ scope.row.text }}
                </p>
              </template> -->
              <!-- <template v-else> -->
              <el-table
                :class="'inner-table'"
                :data="scope.row.data"
                :show-header="false"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <span class="label font-xsm">{{ scope.row.label }}</span>
                    <p class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
              <!-- </template> -->
            </template>
          </el-table-column></el-table
        >
      </template>
    </eden-information-card>
    <eden-information-card
      :title="'Bank Information'"
      style="position: relative"
    >
      <template slot="content">
        <div
          v-if="
            allowAccessFor([
              'superadmin',
              'admin',
              'vendor_manager',
              'operations',
            ])
          "
        >
          <el-button
            class="text-primary add-bank"
            @click="bankAction({ action: 'add' })"
            v-if="data.bank_information?.account_name == null"
          >
            Add Bank Information
          </el-button>
          <el-button
            class="text-primary add-bank"
            @click="bankAction({ action: 'change' })"
            v-else
          >
            Change Bank Information
          </el-button>
        </div>
        <el-table
          v-if="data.bank_information?.account_name !== null"
          :class="'general'"
          :data="bankInfo"
          :show-header="false"
          :span-method="arraySpanMethod"
          empty-text="This vendor hasn’t added any bank information."
        >
          <el-table-column>
            <template slot-scope="scope">
              <template v-if="scope.$index == 0">
                <el-table
                  :class="'inner-table'"
                  :data="scope.row.data"
                  :show-header="false"
                >
                  <el-table-column>
                    <template slot-scope="scope">
                      <span class="label font-xsm">{{ scope.row.label }}</span>
                      <p class="text font-sm">
                        {{ scope.row.text }}
                      </p>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
              <template v-else>
                <div class="ml-1">
                  <span class="label font-xsm">{{ scope.row.label }}</span>
                  <p class="text font-sm">
                    {{ scope.row.text }}
                  </p>
                </div>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <template v-else>
          <div class="is-flex is-justify-center is-align-center empty-view">
            <p class="text-center font-sm">
              This vendor hasn’t added any bank information.
            </p>
          </div>
        </template>
      </template>
    </eden-information-card>
    <eden-information-card :title="'Professional Certificate'">
      <template slot="content">
        <div class="is-flex is-justify-between is-align-center p-3">
          <div class="label font-sm">{{ documentName }}</div>
          <el-button plain @click="openDocumentLink" class="text-primary"
            >View Document</el-button
          >
        </div>
      </template>
    </eden-information-card>
    <eden-information-card
      :title="'Operation Details'"
      style="position: relative"
    >
      <template slot="content">
        <div
          v-if="
            allowAccessFor([
              'superadmin',
              'admin',
              'vendor_manager',
              'operations',
            ])
          "
        >
          <el-button
            class="text-primary add-bank"
            @click="showOperationDetails = true"
          >
            Edit Operation Details
          </el-button>
        </div>
        <el-table
          :class="'general'"
          :data="operations"
          :show-header="false"
          :span-method="arraySpanMethod"
        >
          <el-table-column>
            <template slot-scope="scope">
              <el-table
                :class="'inner-table'"
                :data="scope.row.data"
                :show-header="false"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <span class="label font-xsm">{{ scope.row.label }}</span>
                    <p class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column></el-table
        >
      </template>
    </eden-information-card>

    <BankInformationForm
      :show.sync="command.visibility"
      :action="command.action"
      :banks="banks"
      @bank-details-created="$emit('bank-details-created')"
    />
    <OperationDetailsForm
      :show.sync="showOperationDetails"
      :data="data"
      @success="$emit('bank-details-created')"
    />
  </div>
</template>

<script>
import BankInformationForm from "./BankInformationForm.vue";
import details from "@/requests/vendors/details.js";
import OperationDetailsForm from "./OperationDetailsForm.vue";

export default {
  components: { BankInformationForm, OperationDetailsForm },
  props: {
    data: {
      default: {},
    },
  },
  data() {
    return {
      showBankInformationForm: false,
      showOperationDetails: false,
      command: {
        action: "add",
        visibility: false,
        data: {},
      },
      banks: [],
    };
  },
  created() {
    this.getBanks();
  },
  computed: {
    documentName() {
      const documentLink =
        this.data.business_information?.certificate?.document_link;
      const vendorAccountCreator = this.data.vendor_account_creator;

      const format = documentLink?.split(".").pop().toLowerCase();
      const documentType = "Business Certificate";
      const documentFormat = format === "png" ? "png" : "pdf";

      return `${vendorAccountCreator?.name} ${documentType}.${documentFormat}`;
    },
    socials() {
      return [
        {
          data: [
            {
              label: "Twitter handle",
              text: this.data?.socials?.twitter_link ?? "-",
            },
            {
              label: "Instagram",
              text: this.data?.socials?.instagram_link ?? "-",
            },
          ],
        },
      ];
    },
    operations() {
      return [
        {
          data: [
            {
              label: "Operation Mode",
              text: this.data?.business_information?.operation_mode ?? "-",
            },
            {
              label: "Preparation Time",
              text:
                `${this.data?.business_information?.prep_time} min(s)` ?? "-",
            },
          ],
        },
      ];
    },
    bankInfo() {
      return [
        {
          data: [
            {
              label: "Bank Name",
              text: this.data.bank_information?.bank_name ?? "-",
            },
            {
              label: "Account Number",
              text: this.data.bank_information?.account_number ?? "-",
            },
          ],
        },
        {
          label: "Account Name",
          text: this.data.bank_information?.account_name ?? "-",
        },
      ];
    },
  },
  methods: {
    async getBanks() {
      try {
        const res = await details.listBanks();
        this.banks = res.data.data;
      } catch (err) {
        this.$message.error(err.message);
      }
    },
    arraySpanMethod({ rowIndex }) {
      if (rowIndex === 2) {
        return [1, 2];
      } else if (rowIndex === 3) {
        return [3, 4];
      } else {
        return [1, 4];
      }
    },
    openDocumentLink() {
      window.open(
        this.data.business_information?.certificate?.document_link,
        "_blank",
      );
    },
    bankAction(command) {
      switch (command.action) {
        case "add":
          this.command.action = "add";
          this.command.visibility = true;
          break;
        case "change":
          this.command.action = "change";
          this.command.visibility = true;
          break;
        default:
          this.command.action = "add";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-3 {
  padding: 12px 10px;
}

.empty-view {
  height: 92px;
}

.empty-view p {
  color: var(--eden-grey-quaternary) !important;
}

.add-bank {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
