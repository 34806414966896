<template>
  <el-dialog
    :title="'Publish these products'"
    :visible="show"
    @close="closeDialog"
    append-to-body
  >
    <p>
      Publishing your product means it will be visible to customers and they can
      order it. If you don’t publish, it’ll be saved as a draft and you can
      manually publish them anytime.
    </p>

    <div class="is-flex is-justify-between mt-5">
      <div>
        <el-button type="plain" @click="$emit('go-back')">Go Back</el-button>
      </div>
      <div class="is-flex is-justify-between">
        <el-button @click="saveAsDraft" :loading="saveDraft"
          >Save as draft</el-button
        >
        <el-button type="primary" @click="publishProducts" :loading="creating"
          >Publish products</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import details from "@/requests/vendors/details.js";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      creating: false,
      saveDraft: false,
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.id;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    async saveAsDraft() {
      // Implement save as draft functionality
      this.saveDraft = true;
      const data = this.data.map((product) => ({
        name: product.name,
        price: product.price,
        image_url: product.image_url,
        quantity: product.quantity,
        description: product.description,
        is_unlimited: false,
        use_discounted_price: false,
        is_draft: true,
        category_names: [product.category],
      }));
      const payload = { products: data };
      await details
        .bulkCreate(this.vendorId, payload)
        .then((response) => {
          this.closeDialog();
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            const errorMessages = error.response.data;

            for (const errorMessage of errorMessages.errors) {
              if (errorMessage.includes("name field has a duplicate value.")) {
                const match = errorMessage.match(/The products\.(\d+)\.name/);
                if (match) {
                  const duplicateIndex = parseInt(match[1], 10);
                  this.$message.error(
                    `A product with the same name already exists in item ${
                      duplicateIndex + 1
                    }.`,
                  );
                } else {
                  this.$message.error(
                    "A product with the same name already exists.",
                  );
                }
                return;
              } else if (
                errorMessage.includes("category_names.0 is invalid.")
              ) {
                const match = errorMessage.match(
                  /The selected products\.(\d+)\.category_names\.0/,
                );
                if (match) {
                  const invalidCategoryIndex = parseInt(match[1], 10);
                  this.$message.error(
                    `Category in item ${invalidCategoryIndex + 1} is invalid.`,
                  );
                } else {
                  this.$message.error("The selected category is invalid.");
                }
                return;
              } else if (
                errorMessage.includes("name has already been taken.")
              ) {
                this.$message.error("The product name has already been taken.");
                return;
              }
            }
            this.$message.error(errorMessages.message);
          } else {
            this.$message.error("An error occurred while creating products.");
          }
        })
        .finally(() => {
          this.saveDraft = false;
        });
    },
    async publishProducts() {
      // Implement publish products functionality
      this.creating = true;
      const data = this.data.map((product) => ({
        name: product.name,
        price: product.price,
        image_url: product.image_url,
        quantity: product.quantity,
        description: product.description,
        is_unlimited: false,
        use_discounted_price: false,
        is_draft: false,
        category_names: [product.category],
      }));
      const payload = { products: data };
      await details
        .bulkCreate(this.vendorId, payload)
        .then((response) => {
          this.closeDialog();
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            const errorMessages = error.response.data;

            for (const errorMessage of errorMessages.errors) {
              if (errorMessage.includes("name field has a duplicate value.")) {
                const match = errorMessage.match(/The products\.(\d+)\.name/);
                if (match) {
                  const duplicateIndex = parseInt(match[1], 10);
                  this.$message.error(
                    `A product with the same name already exists in item ${
                      duplicateIndex + 1
                    }.`,
                  );
                } else {
                  this.$message.error(
                    "A product with the same name already exists.",
                  );
                }
                return;
              } else if (
                errorMessage.includes("category_names.0 is invalid.")
              ) {
                const match = errorMessage.match(
                  /The selected products\.(\d+)\.category_names\.0/,
                );
                if (match) {
                  const invalidCategoryIndex = parseInt(match[1], 10);
                  this.$message.error(
                    `Category in item ${invalidCategoryIndex + 1} is invalid.`,
                  );
                } else {
                  this.$message.error("The selected category is invalid.");
                }
                return;
              } else if (
                errorMessage.includes("name has already been taken.")
              ) {
                this.$message.error("The product name has already been taken.");
                return;
              }
            }

            this.$message.error(errorMessages.message);
          } else {
            this.$message.error("An error occurred while creating products.");
          }
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
