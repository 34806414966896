<template>
  <div>
    <eden-information-card :title="'Working Hours'">
      <template slot="content">
        <el-table
          :class="'general'"
          :data="workingHours"
          :show-header="false"
          :span-method="arraySpanMethod"
        >
          <el-table-column>
            <template slot-scope="scope">
              <el-table
                :class="'inner-table'"
                :data="scope.row.data"
                :show-header="false"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <span class="label font-xsm">{{ scope.row.label }}</span>
                    <p v-if="scope.row.is_open" class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                    <p v-else class="text font-sm text-danger">Closed</p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column></el-table
        >
      </template>
    </eden-information-card>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: {},
    },
  },
  computed: {
    workingHours() {
      if (Array.isArray(this.data)) {
        return this.data.reduce((result, item, index) => {
          const groupIndex = Math.floor(index / 2);
          if (!result[groupIndex]) {
            result[groupIndex] = { data: [] };
          }
          result[groupIndex].data.push({
            label: this.formatText(item.day_of_week),
            text: `${item.opening_time} - ${item.closing_time}`,
            is_open: item.is_open,
          });
          return result;
        }, []);
      } else {
        return []; // Return an empty array or handle the case when this.data is not an array
      }
    },
  },
  methods: {
    arraySpanMethod({ rowIndex }) {
      if (rowIndex === 2) {
        return [1, 2];
      } else if (rowIndex === 3) {
        return [3, 4];
      } else {
        return [1, 4];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
